var View = require('ampersand-view');
var templates = require('../../templates');

module.exports = View.extend({
    template: templates.myPayAccount.infoPage,

    initialize: function (config) {
        this.config = config;
    },

    render: function() {
        this.renderWithTemplate();
       
        this.cacheElements({
            message: '[data-hook=message]'
        });

        $(this.message)
            .html(this.config.message);
    }
});

