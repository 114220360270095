var templates = require('../../templates');

// Views
var View = require('ampersand-view');

module.exports = View.extend({
    template: templates.mfaSetup.registrationComplete,
    events: {
        'click [data-hook=finish-button]': 'finishButtonClicked'
    },
    render: function () {
        this.renderWithTemplate();
        this.cacheElements({
            finishButton: '[data-hook=finish-button]'
        });
    },
    finishButtonClicked: function() {
        app.endMfa();
    }
});