var PageView = require('../base');
var templates = require('../../templates');
var LoginForm = require('./login-form');
var LoginModel = require('../../models/login');

module.exports = PageView.extend({
    pageTitle: 'Login',
    template: templates.account.login,
    bindings : {
        'model.loginText': '[data-hook=login]'
    },
    initialize: function () {
        this.model = new LoginModel();
    },
    subviews: {
        form: {
            // this is the css selector that will be the `el` in the
            // prepareView function.
            container: '[data-hook=login-form]',
            prepareView: function (el) {
                return new LoginForm({
                    el: el,
                    model: this.model,
                    submitCallback: app.getEnvironmentAndLogin
                });
            }
        }
    }
});

