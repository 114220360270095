module.exports = function () {
    var _options = arguments[0];
    var _settings = arguments[1] || {};

    if (!_options) {
        throw "Must pass at least one parameter to notify";
    }

    var message = _.isString(_options) ? _options : _options.message ? _options.message : null;
    if (!message) {
        throw "Message must be provided";
    }

    var options = {
        message: message
    };

    var settings = {
        delay: _settings.delay || 2000,
        placement: _settings.placement || {
            from: 'top',
            align: 'center'
        },
        onClosed: _settings.onClosed || null,
        type: _settings.type || 'info'
    };

    $.notify(options, settings);
};