var moment = require('moment');
var templates = require('../../templates');

var AjaxHelper = require('../../helpers/ajaxHelper');
var ViewSwitcher = require('ampersand-view-switcher');

//Models
var RegisteredDevice = require('../../models/registeredDevice');

//Views
var View = require('ampersand-view');
var SecurityQuestionsView = require('./mfa-security-questions-view');
var OnlineAuthView = require('../mfaAuth/mfa-online-auth-view');
var LoadingView = require('../loading-view');

//Constants
var POLL_INTERVAL = 5000;
var POLL_DURATION = 300000;
var NO_DEVICE_MESSAGE = 'No device registered, please open MyPay on the device you want to use as your second factor. ';
var LOADING_VIEW_COLOR = '#d3d3d3';

module.exports = View.extend({
    template: templates.mfaSetup.onlineDeviceSetup,
    props: {
        hasSetupMyPay: ['boolean', true, false],
        deviceInfo: ['string', false, 'No device registered, please open MyPay on the device you want to use as your second factor.'],
        timeElapsed: ['number', false, 0],
        hasAuthenticated: ['boolean', true, false]
    },
    bindings: {
        'hasSetupMyPay': [
            {
                type: 'toggle',
                selector: '[data-hook=next-button]'
            },
            {
                type: 'toggle',
                selector: '[data-hook=refresh-button]',
                invert: true
            },
            {
                type: 'toggle',
                selector: '[data-hook=register-helper-text]',
                invert: true
            },
            //Show the loading spinner when device is not registered
            {
                type: function(indicatorContainer, hasSetupMyPay) {
                    if (!hasSetupMyPay) {
                        if (this.loadingView) {
                            this.loadingView.show();
                        } else {
                            this.loadingView = new LoadingView({
                                target: indicatorContainer,
                                color: LOADING_VIEW_COLOR,
                                length: 5,
                                width: 3.5,
                                radius: 6.67,
                                left: 2,
                                top: 2
                            });
                        }
                    } else {
                        this.loadingView.hide();
                    }
                },
                selector: '[data-hook=input-indicator-container]'
            },
            //Show the green tick indicator when device is registered
            {
                type: 'toggle',
                selector: '[data-hook=input-indicator]'
            }
        ],
        'hasAuthenticated' : {
            type: 'booleanAttribute',
            selector: '[data-hook=next-button]',
            name: 'disabled',
            invert: true
        }
    },
    events: {
        'click [data-hook=next-button]': 'goToNextPage',
        'click [data-hook=refresh-button]': 'fetchModel',
        'click [data-hook=back-button]': 'goBack'
    },
    render: function () {
        this.renderWithTemplate();

        this.cacheElements({
            deviceStatusText: '[data-hook=device-status-text]',
            nextButton: '[data-hook=next-button]',
            manualRegisterButton: '[data-hook=refresh-button]',
            registerHelperText: '[data-hook=register-helper-text]',
            backButton: '[data-hook=back-button]',
            authView: '[data-hook=auth-content]',
            refreshButton: '[data-hook=refresh-button]'
        });

        this.viewSwitcher = new ViewSwitcher(this.authView, {
            show: _.bind(function (view) {
                this.registerSubview(view);
            }, this)
        });

        //Start the polling
        this.model = new RegisteredDevice();
        this.fetchModel();
    },
    fetchModel: function () {
        var _this = this;
        this.timeElapsed += POLL_INTERVAL;

        //This is called when a registered device is found
        var success = function (model) {
            //If the model does not contain data, then no registered devices were found
            if (model.deviceInfo || model.operatingSystem) {
                _this.deviceInfo = "Device " + model.deviceModel + ", " + model.operatingSystem + ", registered on " + moment(model.registeredDateUnixTimeStamp).format('DD MMMM YYYY');
                _this.hasSetupMyPay = true;
                _this.deviceStatusText.innerHTML = _this.deviceInfo;
                //Render the next step
                _this.renderAuthView();
            } else {
                _this.deviceInfo = NO_DEVICE_MESSAGE;
                _this.deviceStatusText.innerHTML = _this.deviceInfo;
                _this.hasSetupMyPay = false;
                $(_this.deviceStatusText).append(_this.refreshButton);
            }
        };

        //This is called when no registered devices are found
        var failure = function () {
            _this.deviceInfo = NO_DEVICE_MESSAGE;
            _this.hasSetupMyPay = false;
        };

        if (!this.hasSetupMyPay) {
            this.model.fetch({
                url: __urlRoot + state.environment + __apiRoot + '/app/v/1/mfa/authdevices?username=' + state.username,
                headers: AjaxHelper.getHeaders(),
                success: success,
                error: failure
            });
        }

        //Poll for a registered device for around 2 minutes, every 5 seconds
        if (this.timeElapsed < POLL_DURATION) {
            setTimeout(function () { _this.fetchModel(); }, POLL_INTERVAL);
        }
    },
    renderAuthView: function () {
        this.onlineAuthView = new OnlineAuthView({
            parent: this,
            sessionExpired: false,
            isSetup: true
        });
        this.onlineAuthView.render();
        this.viewSwitcher.set(this.onlineAuthView);

        this.listenTo(this.onlineAuthView, 'authenticationApproved', function() {
            this.hasAuthenticated = true;
        });

    },
    goToNextPage: function () {
        this.securityQuestionsView = new SecurityQuestionsView();
        this.parent.switchView(this.securityQuestionsView);
    },
    goBack: function() {
        this.parent.switchView(this.parent.initialView);
    }
});