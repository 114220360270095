var cookies = require('cookie-getter');
var dialog = require('bootbox');

var isFunction = require('amp-is-function');
var notify = require('../views/helpers/notify');

var AjaxHelper = require('./ajaxHelper');
var AmpersandModel = require('ampersand-model');
var AmpersandRestCollection = require('ampersand-rest-collection');

// http://stackoverflow.com/questions/6974684/how-to-send-formdata-objects-with-ajax-requests-in-jquery
//
// Api currently requires post data to be sent with ApiData as the key
function getFormData(data) {
    var formData = new FormData();
    formData.append("ApiData", JSON.stringify(data));
    return formData;
}

// normal get and post requests
var normal = function (method, url, success, failure, data, turnOffAsync, timeout) {
    // timeout was passed to cater the slow performance of project leave balance calculation when update leave request
    return $.ajax({
        url: __urlRoot + state.environment + __apiRoot + url,
        type: method || 'GET',
        headers: AjaxHelper.getHeaders(),
        data: getFormData(data),
        processData: false,
        contentType: false,
        async: !turnOffAsync,
        timeout: timeout || 30000,
        success: function (data, status, req) {
            if (isFunction(success)) {
                success(data, status, req);
            }
        },
        error: function (req, status, error) {
            if (req.status === 401) {
                console.log('session timed out');
                return app.trigger('timeout');
            }

            if (isFunction(failure)) {
                failure(req, status, error);
            } else {
                // If the error is coming through as 'unexpected end of input'
                // this is because as of query 1.9 empty responses where json 
                // is expected are being rejected (see http://api.jquery.com/jquery.ajax/)
                dialog.alert({
                    title: 'Error',
                    message: req.getResponseHeader('x-status') || error.message || error
                });
            }
        }
    });
};

function getFilename(xhr) {
    var filename = null;
    var disposition = xhr.getResponseHeader('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
}

var downloadFile = function (url, success, failure, data) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', __apiRoot + url, true);
    xhr.responseType = "arraybuffer";

    _.each(_.pairs(AjaxHelper.getHeaders()), function (header) {
        xhr.setRequestHeader(header[0], header[1]);
    });

    // http://stackoverflow.com/questions/16086162/handle-file-download-from-ajax-post
    xhr.onload = function () {
        if (this.status === 200) {
            // Get file name and type
            var filename = getFilename(xhr);
            var filetype = xhr.getResponseHeader('Content-Type') || "application/pdf";

            var arrayBuffer = xhr.response;
            if (arrayBuffer) {
                var blob = new Blob([xhr.response], { type: filetype });

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing 
                    // the blob for which they were created. These URLs will no longer resolve as 
                    // the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        var a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location = downloadUrl;
                    }

                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                }
            }

            if (isFunction(success)) {
                success();
            }

            // A pop up for selenium
            notify("Downloaded successfully");
        } else if (this.status === 401) {
            console.log('session timed out');
            return app.trigger('timeout');
        } else {
            if (isFunction(failure)) {
                failure(xhr, xhr.status);
            } else {
                // If the error is coming through as 'unexpected end of input'
                // this is because as of query 1.9 empty responses where json 
                // is expected are being rejected (see http://api.jquery.com/jquery.ajax/)
                dialog.alert('An error has occurred');
            }
        }
    };


    xhr.send(getFormData(data));
};

module.exports = {
    get: function (url, success, failure, data, turnOffAsync) {
        normal('GET', url, success, failure, data, turnOffAsync);
    },
    post: function (url, success, failure, data, turnOffAsync, timeout) {
        normal('POST', url, success, failure, data, turnOffAsync, timeout);
    },
    put: function (url, success, failure, data, turnOffAsync, timeout) {
        normal('PUT', url, success, failure, data, turnOffAsync, timeout);
    },
    delete: function (url, success, failure, data, turnOffAsync) {
        normal('DELETE', url, success, failure, data, turnOffAsync);
    },
    download: downloadFile
};