var FormView = require('ampersand-form-view');
var View = require('ampersand-view');
var InputView = require('ampersand-input-view');
var templates = require('../../templates');
var ResetSuccessView = require('./resetSuccess-view');
var dialog = require('bootbox');

//====================================
//Set the input view template
//====================================
var ExtendedInput = InputView.extend({
    template: templates.includes.formInput()
});


var Form = FormView.extend({
    //That form is given an array of field views, which are input views
    fields: function () {
        return [
            new ExtendedInput({
                label: '',
                name: 'username',
                required: true,
                requiredMessage : 'Username is required',
                type: 'text',
                placeholder: 'Username',
                parent: this
            })
        ];
    }
});

module.exports = View.extend({
    template: templates.resetpassword.form,
    render: function() {
        this.renderWithTemplate();
        $.getScript('https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit');
        return this;
    },
    subviews: {
        form: {
            container: '[data-hook=reset-form]',
            prepareView: function (el) {
                return new Form({
                    el: el,
                    submitCallback: ResetPassword
                });
            }
        }
    }
});

function ResetPassword(data) {
    // Switch to working state
    $('.submit-button').button('loading');

    // Get the recaptcha response and notify the user
    // it is required if they haven't completed it
    var captcha = grecaptcha.getResponse();
    if (captcha === "") {
        $('.submit-button').button('reset');
        $('#CaptchaMessage').show();
        return;
    }

    // Cache in success closure
    var username = data.username;

    function failure(req, status, error) {
        // Sometimes the error is an object containing the message
        // and other times it is the message
        dialog.alert({
            title: 'Error',
            message: req.getResponseHeader('x-status') || error.message || error
        });
        $('.submit-button').button('reset');
    }

    function success(environment) {
        $.ajax({
            url: __urlRoot + environment + __apiRoot + '/app/v/1/resetpassword',
            type: 'POST',
            headers: {
                "x-username": username,
                "g-recaptcha-response": captcha,
                "x-app-type": 'DirectAccess'
            },
            success: function () {
                // workaround recaptcha issue where iframe continues to intercept clicks
                $('.pls-container').remove();
                
                // Show message
                app.router.trigger('page', new ResetSuccessView());
            },
            error: failure
        });
    }

    app.getEnvironment(username, success, failure);
}