var View = require('ampersand-view');
var templates = require('../../templates');
var _ = require('underscore');

module.exports = View.extend({
    template: templates.myPayAccount.successPage,
    render: function() {
        this.renderWithTemplate({location: window.location.origin + __urlRoot});
       
        this.cacheElements({
            successMessage: '[data-hook=success-message]'
        });

        $(this.successMessage)
            .text('Your password has been reset successfully.');
    }
});

