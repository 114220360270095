/*global me, app*/
var Router = require('./helpers/ampersand-routefilter');
var LoginPage = require('./views/login/login-page');
var ResetPasswordForm = require('./views/resetpassword/reset-form');
var NewPasswordForm = require('./views/newpassword/newPassword-form');
var NewPasswordInvalidView = require('./views/newpassword/newPasswordInvalid');
var MfaSetupView = require('./views/mfaSetup/mfa-setup-main-view');
var MfaAuthView = require('./views/mfaAuth/mfa-auth-main-view');
var MyPayActivatePage = require('./views/myPayAccount/activate-account-form');
var MyPayResetPasswordPage = require('./views/myPayAccount/reset-password-form');
var MyPay404Page = require('./views/myPayAccount/404-page');

var myPayUrl = '/mypay';

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

module.exports = Router.extend({
    routes: {
        'login': 'login',
        'resetpassword': 'reset',
        'newpassword': 'newPassword',
        'mfasetup': 'mfaSetup',
        'mfaauth': 'mfaAuth',
        'mfasetupsecurityquestions': 'mfaSetupSecurityQuestions',
        'mypay': 'myPayActivate',
        'mypay/resetpassword': 'myPayResetPassword',
        'mypay/404': 'myPayPageNotFound',
        '(*path)': 'catchAll'
    },

    // ------- ROUTE HANDLERS ---------
    login: function () {
        this.trigger('page', new LoginPage());
    },

    reset: function () {
        // Warning: there are issues with the recaptcha creation
        // that mean this page must always be accessed via a 
        // full page load (i.e. browser navigation)
        this.trigger('page', new ResetPasswordForm());
    },

    newPassword: function () {
        // Need to parse the query string and pass in as configuration
        var token = getParameterByName('token');
        var username = getParameterByName('username');
        var environment = getParameterByName('env');
        var onboard = getParameterByName('selfonboard');

        // Three parameters are needed
        if (token === "" || username === "" || environment === "") {
            this.trigger('page', new NewPasswordInvalidView());
        }
        else if (app.featureToggles &&
            app.featureToggles.PasswordResetPageSecurityFix === "true" &&
            isNaN(environment)) {
            this.trigger('page', new NewPasswordInvalidView());
        } else {
            $.ajax({
                url: __urlRoot + environment + __apiRoot + '/app/v/1/verifytoken',
                type: 'POST',
                headers: {
                    "x-username": username,
                    "x-token": token,
                    "x-token-type": 2,
                    "x-app-type": 'DirectAccess'
                },
                success: function () {
                    // Token is valid, redirect to new password page
                    var config = {
                        username: username,
                        token: token,
                        environment: environment,
                        onboard: onboard
                    };
                    app.router.trigger('page', new NewPasswordForm(config));
                },
                error: function () {
                    // Token has expired, show invalid message
                    app.router.trigger('page', new NewPasswordInvalidView());
                }
            });
        }
    },
    mfaSetup: function () {
        if (window.state.isLoggedIn) {
            this.trigger('mfa', new MfaSetupView());
            this.trigger('hideLogo');
        } else {
            this.redirectTo('login');
        }
    },
    mfaAuth: function () {
        if (window.state.isLoggedIn) {
            if (window.state.passedMfaAuth) {
                app.endMfa();
            } else {
                this.trigger('mfa', new MfaAuthView());
            }
        } else {
            this.redirectTo('login');
        }
    },
    mfaSetupSecurityQuestions: function() {
        if (window.state.isLoggedIn) {
            this.trigger('mfa', new MfaSetupView({
                redirectToSecurityQuestions: true
            }));
            this.trigger('hideLogo');
        }  
    },
    myPayActivate: function() {
        this.trigger('page', new MyPayActivatePage());
    },
    myPayResetPassword: function() {
        this.trigger('page', new MyPayResetPasswordPage());
    },
    myPayPageNotFound: function () {
        this.trigger('page', new MyPay404Page());
    },
    catchAll: function () {
        if(document.URL.indexOf(myPayUrl) !== -1){
            this.redirectTo('mypay/404');
        }else{
            this.redirectTo('login');
        }
    }
});
