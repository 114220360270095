var View = require('ampersand-view');
var templates = require('../../templates');

module.exports = View.extend({
    template: templates.newpassword.success,
    initialize: function (config) {
        this.config = config;
    },
    events: {
        'click button': 'goToLogin'
    },
    goToLogin: function () {
        app.navigate('login');
    },
    render: function () {
        this.renderWithTemplate({ onboard: this.config.onboard });
    }
});