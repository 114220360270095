var FormView = require('ampersand-form-view');
var InputView = require('ampersand-input-view');
var templates = require('../../templates');

//====================================
//Set the input view template
//====================================
var ExtendedInput = InputView.extend({
    template: templates.includes.formInput()
});

var UsernameInput = ExtendedInput.extend({
    initialize: function () {
        // call parent constructor
        InputView.prototype.initialize.apply(this, arguments);

        // listen for changes to input value
        this.on('change:inputValue', this.updateUsername, this);
    },
    updateUsername: function()
    {
        // Want to update the model so that we can check the
        // environment and update the login text
        this.parent.model.username = this.inputValue;
    }
});

module.exports = FormView.extend({
    //That form is given an array of field views, which are input views
    fields: function () {
        return [
            new UsernameInput({
                label: '',
                name: 'username',
                value: this.model && this.model.username,
                required: true,
                requiredMessage : 'Username is required',
                type: 'text',
                placeholder: 'Username',
                parent: this
            }),
            new ExtendedInput({
                label: '',
                name: 'password',
                value: this.model && this.model.password,
                required: true,
                requiredMessage: 'Password is required',
                type: 'password',
                placeholder: 'Password',
                parent: this
            })
        ];
    }
});
