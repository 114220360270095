var View = require("ampersand-view");
var templates = require("../../templates");

// Views
var LoadingView = require('../loading-view');

// Helpers
var ajax = require("../../helpers/ajax");
require('../../../vendor/bootstrap-pincode-input');

// Constants
var LOADING_VIEW_COLOR = '#d3d3d3';

module.exports = View.extend({
    template: templates.mfaAuth.offlineAuth,
    props: {
        isVerifying: ["boolean", true, false],
        showIndicator: ["boolean", true, false],
        hasPassedAuth: ["boolean", true, false],
        isSecondAuth: ["boolean", true, false],
        titleText: ["string", true, "Please enter the 6-digit code displayed in your authentication app"],
        alertMessageText: ["string", true, ""],
        hasSetupSecurityQuestions: ["boolean", true, true]
    },
    bindings: {
        'hasPassedAuth': {
            type: 'booleanClass',
            hook: 'input-indicator',
            yes: ['fa-check-circle', 'success-font'],
            no: ['fa-times-circle', 'fail-font']
        },
        'showIndicator':
        {
            type: "toggle",
            hook: "input-indicator"
        },
        'isVerifying': {
            type: "toggle",
            hook: "auth-input-loading-view"
        },
        'isSecondAuth': [
            {
                type: "booleanClass",
                hook: "offline-title",
                yes: 'mfa-text',
                no: 'mfa-title'
            },
            {
                type: function(el, isSecondAuth) {
                    if (isSecondAuth) {
                        this.titleText = "Enter the 6-digit code displayed in your authentication app";
                    }
                }
            },
            {
                type: "toggle",
                hook: "or-container"
            }
        ],
        'titleText': {
            type: "text",
            hook: "offline-title"
        },
        'alertMessageText': {
            type: "text",
            hook: "alert-message"
        },
        'showMessage': {
            type: "toggle",
            hook: "alert-message"
        }
    },
    derived: {
        'showMessage':{
            deps: ['alertMessageText'],
            fn: function() {
                return this.alertMessageText !== "";
            }
        }
    },
    initialize: function() {
        this.renderWithTemplate();

        this.cacheElements({
            authInput: "[data-hook=auth-input]",
            authInputLoadingViewContainer: "[data-hook=auth-input-loading-view]",
            inputIndicator: "[data-hook=input-indicator]",
            inputIndicatorContainer: "[data-hook=input-indicator-container]",
            offlineTitle: "[data-hook=offline-title]",
            alertMessage: "[data-hook=alert-message]"
        });

        this.alertMessageText = "";
    },
    render: function() {
        // Display input as a pincode input
        $(this.authInput).pincodeInput({
            inputs: 6,
            hidedigits: false,
            change: this.updateAuthInput.bind(this),
            complete: this.verifyAuthInput.bind(this)
        });
    },
    updateAuthInput: function() {
        var input = $(this.authInput);
        if (input.val().length !== 6) {
            this.isVerifying = false;
            this.showIndicator = false;
            this.hasPassedAuth = false;
        }
    },
    verifyAuthInput: function() {
        var input = $(this.authInput);

        var success = function (response) {
            this.showIndicator = true;
            this.isVerifying = false;

            if (response.Result) {
                this.hasPassedAuth = true;
                if (!this.hasSetupSecurityQuestions) {
                    app.notifyParentPage({ mfaToken: response.MfaToken });
                    app.navigate('mfasetupsecurityquestions');
                } else {
                    this.parent.renderTrustThisDeviceView(response.MfaToken);
                }
            }
            else {
                this.hasPassedAuth = false;
                this.alertMessageText = 'The authentication code you provided is invalid. Please try again.';
                input.pincodeInput().data('plugin_pincodeInput').clear();
                input.pincodeInput().data('plugin_pincodeInput').focus();
            }

        }.bind(this);

        var error = function(req, status, error) {
            this.showIndicator = true;
            this.isVerifying = false;
            this.hasPassedAuth = false;
            this.alertMessageText = error;
            $(this.parent.securityQuestionButton).hide();

            input.pincodeInput().data('plugin_pincodeInput').clear();
            input.pincodeInput().data('plugin_pincodeInput').disable();
        }.bind(this);


        // Display loading spinner
        if (!this.loadingView) {
            this.loadingView = new LoadingView({
                target: this.authInputLoadingViewContainer,
                color: LOADING_VIEW_COLOR
            });
        }

        // Verify input
        if (!this.isVerifying) {
            this.isVerifying = true;
            ajax.post("/app/v/1/mfa/authoffline", success, error, {
                userName: state.username,
                timeBasedToken: input.val()
            });
        }
    }
});