var templates = require('../../templates');

var RegisterView = require('./mfa-setup-register-view');
var View = require('ampersand-view');
var ViewSwitcher = require('ampersand-view-switcher');
var SecurityQuestionsView = require('./mfa-security-questions-view');

module.exports = View.extend({
    template: templates.mfaSetup.main,
    props: {
        redirectToSecurityQuestions: ["boolean", false, false]
    },
    initialize: function () {

        this.renderWithTemplate();

        this.cacheElements({
            setupView: '[data-hook=mfa-setup-view-container]'
        });

        this.viewSwitcher = new ViewSwitcher(this.setupView, {
            show: _.bind(function (view) {
                this.registerSubview(view);
            }, this)
        });

        // Default to the setup type selection page
        if (this.redirectToSecurityQuestions) {
            this.initialView = new SecurityQuestionsView({
                parent: this
            });
        } else {
            this.initialView = new RegisterView({
                parent: this
            });
        }
        this.initialView.render();
        this.viewSwitcher.set(this.initialView);

        // Listen to triggers
        this.on("offlineSetupView:clicked:back", function() {
            this.showAppLinks(true);
            this.switchView(this.initialView);
        }.bind(this));
    },
    switchView: function(view) {
        view.parent = this;
        view.render();

        this.viewSwitcher.set(view);
    },
    showAppLinks: function(show) {
        if (show) {
            $(this.parent.appLinks).show();
        } else {
            $(this.parent.appLinks).hide();
        }
    }
});