var AmpersandModel = require('ampersand-model');

module.exports = AmpersandModel.extend({
    props: {
        questionId: {
            type: 'number',
            default: 0
        },
        question: {
            type: 'string',
            default: ''
        },
        answer: {
            type: 'string',
            default: ''
        },
        username: {
            type: 'string',
            default: ''
        }
    }
});