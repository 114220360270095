var templates = require('../../templates');
// Use this to build the HTML and then use bootstrap-select
// style and get values later
var SelectView = require('ampersand-select-view');
var _ = require('underscore');

module.exports = SelectView.extend({
    template: templates.inputs.selectInput,
    initialize: function (config) {
        SelectView.prototype.initialize.apply(this, arguments);
        this.config = {
            noneSelectedText: config.noneSelectedText !== undefined ? config.noneSelectedText : 'Nothing selected',
            conditionalActions: config.conditionalActions
        };
    },
    render: function () {
        SelectView.prototype.render.apply(this, arguments);

        // Create the bootstrap UI
        $(this.select).selectpicker({
            width: '100%',
            noneSelectedText: this.config.noneSelectedText
        });

        var messageDiv = document.createElement('div');
        messageDiv.innerHTML = "<a data-hook='input-error' class='fa fa-exclamation-circle select-view-input-error'></a><div data-hook='alert-danger' class='alert alert-danger select-view-alert mfa-alert-danger'>This field is required.</div>";
        $(this.el).prepend(messageDiv);

        this.cacheElements({
            inputError: '[data-hook="input-error"]',
            dangerAlert: '[data-hook="alert-danger"]'
        });

        // Listen to UI change
        $(this.select).on('change', _.bind(function (event) {
            this.setValue(event.target.value);

            if (this.controlModel) {
                this.controlModel.value = this.value;
            }

            this.trigger('change:value');
            this.trigger('value:selected', event.target);

            if (this.required && !this.value) {
                this.showErrorIcon();
            } else {
                this.hideErrorIcon();
            }

            this.updateTooltip();
        }, this));

        // This is required for jira desdp-10649. For some reason on the leave request screen
        // an extra click event is being fired which makes the dropdown reopen
        $(this.select).on('show.bs.select', function() {
            $('.leave-form .dropdown-backdrop').on('click', function (e) {
                e.preventDefault();
            });
        });
    },
    setValue: function (value) {
        // Update bootstrap UI
        $(this.select).selectpicker('val', value);
        this.trigger('valchange', value);
        return SelectView.prototype.setValue.apply(this, arguments);
    },
    derived: {
        changed: {
            deps: ['value', 'startingValue'],
            fn: function () {
                return this.value !== this.startingValue;
            }
        },
        md: {
            deps: ['width'],
            fn: function () {
                return this.width === 1 ? 'col-md-6' : 'col-md-12';
            }
        },
        lg: {
            deps: ['width'],
            fn: function () {
                return this.width === 1 ? 'col-lg-6' : 'col-lg-12';
            }
        }
    },
    props: {
        value: 'any',
        startingValue: 'any',
        disabled: ['boolean', false, false],
        width: ['number', false, 1],
        required: ['boolean', false, false]
    },
    bindings: _.extend({}, SelectView.prototype.bindings, {
        'disabled': [{
            type: 'booleanAttribute',
            selector: 'select',
            name: 'disabled'
        },
        {
            type: 'booleanClass',
            selector: 'select',
            name: 'select-view-disabled'
        },
        {
            type: 'booleanClass',
            selector: 'button',
            name: 'disabled'
        },
        {
            type: 'booleanClass',
            selector: 'button',
            name: 'disabled'
        },
        {
            type: 'booleanClass',
            selector: 'li',
            name: 'disabled'
        }],
        'required': {
            type: 'booleanClass',
            name: 'input-required',
            hook: 'label'
        },
        'md': {
            type: 'class',
            hook: 'input-width'
        },
        'lg': {
            type: 'class',
            hook: 'input-width'
        }
    }),
    // After a form has been saved we want to reset so the 
    // new value is the startingValue
    resetStartingValue: function () {
        this.startingValue = this.value;
    },
    // A method to update the options if the options are dynamic
    updateOptions: function (options, selectedValue) {
        if (options.length > 0) {
            // Update Ampersand select view options
            this.options = options;

            // Remove all the options first
            $(this.select).find('option').not(':first').remove();

            // Append new ones
            _.each(options, _.bind(function (option) {
                $(this.select).append('<option value="' + option + '" label="' + option + '">' + option + '</option>');
            }, this));

            // Update the UI
            $(this.select).selectpicker('refresh');

            // Workaround for IE 11 messing up bootstrap-select, see: https://github.com/snapappointments/bootstrap-select/issues/834
            $(this.select).selectpicker('deselectAll');
            $(this.select).selectpicker('refresh');

            if (selectedValue) {
                this.setValue(selectedValue);
            }

            this.updateTooltip();
        }
    },
    updateTooltip: function () {
        $(this.el).find('button').attr('title', this.value);
    },
    beforeSubmit: function () {
        // at the point where we've tried
        // to submit, we want to validate
        // everything from now on.
        if (this.required && !this.errorMessageShowing) {
            this.runTests();
        }
    },
    runTests: function () {        
        if (!this.value && this.required) {
            this.showErrorIcon();
        } else {
            this.hideErrorIcon();
        }
    },
    //Handle with hover icon to see error message
    events: _.extend({}, SelectView.prototype.events, {
        'mouseover .fa-exclamation-circle': 'showErrorMessage',
        'mouseout .fa-exclamation-circle': 'hideErrorMessage'
    }),
    showErrorMessage: function () {
        $(this.dangerAlert).show();
    },
    hideErrorMessage: function () {
        $(this.dangerAlert).hide();
    },
    showErrorIcon: function() {
        $(this.inputError).css("display", "block");
        this.errorMessageShowing = true;
    },
    hideErrorIcon: function() {
        $(this.inputError).css("display", "none");
        this.errorMessageShowing = false;
    }
});
