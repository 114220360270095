var AmpersandModel = require('ampersand-model');

module.exports = AmpersandModel.extend({
    props: {
        deviceModel: {
            type: 'string',
            default: ''
        },
        operatingSystem: {
            type: 'string',
            default: ''
        },
        secureAuthId: {
            type: 'string',
            default: ''
        },
        registeredDate: {
            type: 'date',
            default: ''
        }
    }
});