var ajax = require('../../helpers/ajax');
var templates = require('../../templates');

var OfflineDeviceSetupView = require('./mfa-offline-device-setup-view');
var OnlineDeviceSetupView = require('./mfa-online-device-setup-view');
var View = require('ampersand-view');

module.exports = View.extend({
    template: templates.mfaSetup.register,
    render: function () {
        var _this = this;
        this.renderWithTemplate();

        this.cacheElements({
            setupOnlineAuth: '[data-hook=setup-online-auth]',
            setupOfflineAuth: '[data-hook=setup-offline-auth]',
            skipRegister: '[data-hook=skip-register]',
            setupInstruction: '[data-hook=setup-instruction]'
        });

        // Set up button handlers
        this.skipRegister.onclick = function () {
            this.disabled = true;
            var success = function(response) {
                app.endMfa(response.MfaToken);
            };
            ajax.post('/app/v/1/mfa/skipsetup?username=' + state.username, success);
        };

        this.setupOnlineAuth.onclick = function() {
            _this.parent.switchView(new OnlineDeviceSetupView({
                parent: _this.parent
            }));
        };

        this.setupOnlineAuth.onclick = function () {
            _this.parent.switchView(new OnlineDeviceSetupView({
                parent: _this.parent
            }));
        };

        this.setupOfflineAuth.onclick = function() {
            _this.parent.switchView(new OfflineDeviceSetupView());
        };

        // Set up tooltip text
        this.setupOnlineAuth.title = 'The MyPay option requires you to have installed and downloaded MyPay before continuing on to the next step.';
        this.setupOfflineAuth.title = 'The authenticator option requires you to download an authenticator app to generate codes for you to enter on login to the application.';
        this.skipRegister.title = 'You may only skip this setup once. On your next login you will be required to complete this setup.';
        $(this.setupOnlineAuth).tooltip();
        $(this.setupOfflineAuth).tooltip();
        $(this.skipRegister).tooltip();

        // If the user has already skipped before, disable the skip button
        if (state.hasSkippedMfaSetup) {
            $(this.skipRegister).hide();
        }
        //Hide Mypay button if user is not an employee
        if (!state.isEmployee) {
            $(this.setupOnlineAuth).hide();
            $(this.setupInstruction).text("Please set up your account for two factor authentication by using an authenticator.");
        }
    }
});