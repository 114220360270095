/*global app, me, $*/
// This app view is responsible for rendering all content that goes into
// <html>. It's initted right away and renders itself on DOM ready.
//This is the starting view that will show login first.
//Then switch the view to main-view when login is successful
var View = require('ampersand-view');
var ViewSwitcher = require('ampersand-view-switcher');
var _ = require('underscore');
var dom = require('ampersand-dom');
var templates = require('../templates');
var setFavicon = require('favicon-setter');

module.exports = View.extend({
    initialize: function () {
        this.listenTo(app.router, 'fullPage', this.handleNewPage);
    },
    render: function () {
        // init and configure our page switcher
        // Setting the switcher to app
        this.pageSwitcher = app.mainSwitcher = new ViewSwitcher(this.query('body'), {
            // whether or not to wait for remove to be done before starting show
            waitForRemove: true,
            // here we provide a few things to do before the element gets
            // removed from the DOM.
            hide: function (oldView, cb) {
                // it's inserted and rendered for me so we'll add a class 
                // that has a corresponding CSS transition.
                oldView.el.classList.add('animated');
                oldView.el.classList.add('fadeOutLeft');

                // give it time to finish (yes there are other ways to do this)
                setTimeout(cb, 400);
            },
            // here we provide a few things we'd like to do each time
            // we switch pages in the app.
            show: function (newView) {
                // it's inserted and rendered for me
                document.title = _.result(newView, 'pageTitle') || 'Direct Access';
                document.scrollTop = 0;

                // add a class specifying it's active
                dom.addClass(newView.el, 'active');

                // store an additional reference, just because
                app.currentPage = newView;
            }
        });

        setFavicon('images/favicon.png');
        return this;
    },

    handleNewPage: function (view) {
        // tell the view switcher to render the new one
        this.pageSwitcher.set(view);
    }
});
