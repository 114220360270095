var View = require('ampersand-view');
var templates = require('../../templates');

module.exports = View.extend({
    template: templates.myPayAccount.infoPage,
    render: function() {
        this.renderWithTemplate();

        $(this.queryByHook('message')).html('Page not found.');
    }
});

