var InputView = require('ampersand-input-view');
var dom = require('ampersand-dom');
var templates = require('../../templates');
var _ = require('underscore');

module.exports = InputView.extend({
    template: templates.inputs.formInput,
    props: {
        disabled: ['boolean', false, false],
        hasError: ['boolean', false, false],
        hasWarning: ['boolean', false, false],
        width: ['number', false, 1]
    },
    derived: {
        // Bootstrap responsive width classes, i.e. col-xs-*, col-sm-*, col-md-*, col-lg-*
        // For xs and sm they will always be taking whole width (12), so no derived property here
        md: {
            deps: ['width'],
            fn: function() {
                return this.width === 1 ? 'col-md-6' : 'col-md-12';
            }
        },
        lg: {
            deps: ['width'],
            fn: function () {
                return this.width === 1 ? 'col-lg-6' : 'col-lg-12';
            }
        }
    },
    bindings: _.extend({}, InputView.prototype.bindings, {
        'disabled': {
            type: 'booleanAttribute',
            selector: 'input',
            name: 'readonly'
        },
        'required': {
            type: 'booleanClass',
            name: 'input-required',
            hook: 'label'
        },
        'md': {
            type: 'class',
            hook: 'input-width'
        },
        'lg': {
            type: 'class',
            hook: 'input-width'
        }
    }),
    //Handle with hover icon to see error or warning message
    events: _.extend({}, InputView.prototype.events, {
        'mouseover .fa-exclamation-circle': 'showErrorMessage',
        'mouseout .fa-exclamation-circle': 'hideErrorMessage',
        'mouseover .fa-exclamation-triangle': 'showWarningMessage',
        'mouseout .fa-exclamation-triangle': 'hideWarningMessage'
    }),
    showErrorMessage: function () {
        $(this.el).find(".alert-danger").show();
    },
    hideErrorMessage: function () {
        $(this.el).find(".alert-danger").hide();
    },
    showWarningMessage: function () {
        $(this.el).find(".alert-warning").show();
    },
    hideWarningMessage: function () {
        $(this.el).find(".alert-warning").hide();
    },
    showErrorIcon: function () {
        this.hideWarningIcon();
        $(this.el).find(".error-message-container").show();
        this.hasError = true;
    },
    hideErrorIcon: function () {
        $(this.el).find(".error-message-container").hide();
        this.hasError = false;
    },
    setErrorMessage: function (message) {
        $(this.el).find(".alert-danger").text(message);
    },
    setErrorHtml: function(message) {
        $(this.el).find(".alert-danger").html(message);
    },
    showWarningIcon: function () {
        this.hideErrorIcon();
        $(this.el).find(".warning-message-container").show();
        this.hasWarning = true;
    },
    hideWarningIcon: function () {
        $(this.el).find(".warning-message-container").hide();
        this.hasWarning = false;
    },
    setWarningMessage: function (message) {
        $(this.el).find(".warning-message-text").text(message);
    },
    resetValidation: function () {
        this.hideWarningIcon();
        this.hideErrorIcon();
    },
    hide: function() {
        dom.hide(this.query("[data-hook=input-width]"));
    },
    show: function() {
        dom.show(this.query("[data-hook=input-width]"));
    }
});