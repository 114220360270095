var View = require('ampersand-view');
var ViewSwitcher = require('ampersand-view-switcher');
var templates = require('../templates');
var _ = require('underscore');
var platform = require('platform');

// Constants
var IOS_DOWNLOAD_LINK = 'https://apps.apple.com/nz/app/datacom-mypay/id898099279';
var ANDROID_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=com.datacom.mypay';

module.exports = View.extend({
    template: templates.myPayMain,
    initialize: function () {
        this.listenTo(app.router, 'page', this.handleNewPage);
    },
    props: {
        devicePlatform: ['string', '', ''],
        hideAndroid: ['boolean', false, false],
        hideiOS: ['boolean', false, false],
        location: ['string', '', '']
    },
    bindings: {
        'hideAndroid': {
            type: 'booleanClass',
            hook: 'android-download-link',
            name: 'hidden'
        },
        'hideiOS': {
            type: 'booleanClass',
            hook: 'ios-download-link',
            name: 'hidden'
        }
    },
    render: function () {

        this.devicePlatform = platform.os.family;

        // Checking platform for downloading MyPay app
        // If not iOS or Android, show both download links
        if (!(this.devicePlatform !== 'Android' && this.devicePlatform !== 'iOS')) {
            this.hideAndroid = this.devicePlatform !== 'Android';
            this.hideiOS = this.devicePlatform !== 'iOS';
        } 

        var renderConfig = {
            location: window.location.origin + __urlRoot,
            iosDownloadLink: IOS_DOWNLOAD_LINK,
            androidDownloadLink: ANDROID_DOWNLOAD_LINK
        };
        
        this.renderWithTemplate(renderConfig);

        this.cacheElements({
            subtitle: '[data-hook=subtitle]'
        });
        
        this.pageSwitcher = new ViewSwitcher(this.queryByHook('page-container'), {
            show: function (newView) {
                document.title = _.result(newView, 'pageTitle') || '';
                document.scrollTop = 0;

                // Set subtitle
                $(this.subtitle).text(document.title);
            }.bind(this)
        });
    },
    handleNewPage: function (view) {
        this.pageSwitcher.set(view);
    }
});
