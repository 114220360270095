var View = require('ampersand-view');
var templates = require('../../templates');
var _ = require('underscore');

module.exports = View.extend({
    template: templates.myPayAccount.successPage,
    initialize: function(config) {
        this.showUsername = config.showUsernameOnSuccess;
        this.username = config.username;
    },
    render: function() {
        this.renderWithTemplate({location: window.location.origin + __urlRoot});
       
        this.cacheElements({
            successMessage: '[data-hook=success-message]'
        });

        $(this.successMessage)
            .html('Your account has been activated. Click the following button to download MyPay app.' + (this.showUsername ? '<br>Your username: ' + this.username : ''));
    }
});

