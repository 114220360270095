var View = require('ampersand-view');
var ViewSwitcher = require('ampersand-view-switcher');
var dom = require('ampersand-dom');
var templates = require('../templates');
var _ = require('underscore');
var AccessibilityHelper = require('./helpers/accessibilityHelper');
var DetectIE = require('./helpers/detectIE');

// This view contains the logo's and download icons that are present
// on all our main pages. It will handle switching the form content
// in the middle
module.exports = View.extend({
    template: templates.main,
    events: {
        'click [data-hook=accessibility-toggle]': 'handleToggleAccessibilityMode'
    },
    props: {
        isMfa: ['boolean', true, false],
        hideDirectAccessLogo: ['boolean', true, false]
    },
    bindings: {
        'isMfa': [
            {
                type: 'booleanClass',
                selector: '[data-hook=page-container-wrapper]',
                name: ['col-sm-8', 'col-sm-offset-2', 'mfa-row']
            },
            {
                type: 'booleanClass',
                selector: '[data-hook=page-container-wrapper]',
                name: ['col-sm-4', 'col-sm-offset-4', 'login-row'],
                invert: true
            },
            {
                type: 'toggle',
                hook: 'accessibility-mode',
                invert: true
            }
            
        ],
        'hideDirectAccessLogo': {
            type: 'toggle',
            selector: '[data-hook=da-title-logo]',
            invert: true
        }
    },
    initialize: function () {
        this.listenTo(app.router, 'page', this.handleNewPage);
        this.listenTo(app.router, 'mfa', this.handleMfa);
        this.listenTo(app.router, 'hideLogo', this.hideLogo);
    },
    render: function () {
        var isIE11OrBelow = DetectIE.isIE11OrBelow();

        // main renderer
        this.renderWithTemplate({
            enableAccessibilityToggle: !isIE11OrBelow
        });

        this.cacheElements({
            appLinks: '[data-hook=app-links]',
            accessibilityToggle: '[data-hook=accessibility-toggle]'
        });

        // init and configure our page switcher
        this.pageSwitcher = new ViewSwitcher(this.queryByHook('page-container'), {
            // here we provide a few things we'd like to do each time
            // we switch pages in the app.
            show: function (newView) {
                // it's inserted and rendered for me
                document.title = _.result(newView, 'pageTitle') || 'Direct Access';
                document.scrollTop = 0;

                // add a class specifying it's active
                dom.addClass(newView.el, 'active');
            }
        });

        // Initialise accessibility toggle
        $(this.accessibilityToggle).prop('checked', state.enableAccessibility);
        AccessibilityHelper.adjustColourContrast();

        if (isIE11OrBelow) {
            AccessibilityHelper.enableCompatibilityForIE();
        }

        return this;
    },
    handleNewPage: function (view) {
        // tell the view switcher to render the new one
        this.isMfa = false;
        this.pageSwitcher.set(view);
    },
    handleMfa: function(mfaView) {
        this.isMfa = true;
        mfaView.parent = this;
        this.pageSwitcher.set(mfaView);
    },
    hideLogo: function() {
        this.hideDirectAccessLogo = true;
    },
    handleToggleAccessibilityMode: function () {
        state.enableAccessibility = !state.enableAccessibility;
        state.writeToLocalStorage();
        AccessibilityHelper.adjustColourContrast();
    }
});
