var cookies = require('cookie-getter');

function getToken() {
    // If we don't have the token cached then
    // read from cookie
    if (!state.csrfToken) {
        state.csrfToken = cookies('csrf-token');
    }

    return state.csrfToken;
}

function getHeaders() {
    return {
        // Always send back our csrf token in the request header
        'x-csrf-token': getToken(),
        'x-username': state.username,
        'x-app-type': 'DirectAccess'
    };
}

function errorWrapper(model, response, options, errorHandler) {
    if (response.statusCode === 401) {
        console.log('session timed out');
        return app.trigger('timeout');
    }

    if (errorHandler) {
        errorHandler(model, response, options);
    }
}

module.exports = {
    getHeaders: getHeaders,
    errorWrapper: errorWrapper,
    getToken: getToken
};