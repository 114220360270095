var FormView = require('ampersand-form-view');
var Input = require('./input-view');
var PageView = require('./mypay-base');
var ActivateSuccessPage = require('./activate-success-page');
var ErrorPage = require('./error-page');
var templates = require('../../templates');
var UserAuthInfo = require('../../models/userAuthInfo');
var errorConstants = require('../../helpers/constants/error-constants');

// Form view
var AccountActivateForm = FormView.extend({
    fields: function () {
        return [
            new Input({
                label: 'Username',
                name: 'username',
                value: '',
                required: this.parent.showUsernameField,
                requiredMessage : 'Username is required',
                type: 'text',
                visibility: this.parent.showUsernameField,
                parent: this,
                tests: [
                    function () {
                        if (this.parent.showUsernameField && this.getField('username').showError) {
                            return "Username is unavailable.";
                        }
                    }.bind(this)
                ]
            }),
            new Input({
                label: 'Password',
                name: 'password',
                value: '',
                required: true,
                requiredMessage: 'Password is required',
                type: 'password',
                parent: this,
                hasHelp: true,
                helpMessage: 'Your password must be at least eight characters long, and contain at least one character from each of the following four groups:<br>- lower case letters (a-z)<br>- upper case letters (A-Z)<br>- numbers (0-9)<br>- symbols (!@#$%^*()+=)',
                tests: [
                    function (val) {                        
                        this.parent.getField('confirmpassword').checkError();

                        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^*()+=]).{8,}$/;
                        if (!re.test(val)) {
                            var errorMsg = "Your password doesn't meet requirements.";
                            return errorMsg;
                        }
                    }
                ]
            }),
            new Input({
                label: 'Confirm Password',
                name: 'confirmpassword',
                value: '',
                required: true,
                requiredMessage: 'Password is required',
                type: 'password',
                parent: this,
                tests: [
                    function(val) {
                        if (val !== document.getElementsByName('password')[0].value) {
                            return "Please enter same password";
                        }
                    }
                ]
            }),
            new Input({
                label: 'Your Last Name',
                name: 'lastname',
                value: '',
                required: true,
                requiredMessage: 'Last name is required',
                type: 'text',
                parent: this,
                tests: [
                    function () {
                        if (this.showError) {
                            return "Last name is invalid.";
                        }
                    }
                ]
            })
        ];
    }
});

// Page view
module.exports = PageView.extend({
    pageTitle: 'Activate Your Account',
    template: templates.myPayAccount.formPage,
    subviews: {
        form: {
            container: '[data-hook=account-form]',
            waitFor: 'model',
            prepareView: function(el) {
                this.form = new AccountActivateForm({
                    el: el,
                    parent: this
                });
                return this.form;
            }
        }
    },

    initialize: function () {
        this.userAuthInfo = new UserAuthInfo();
        this.validateUrl();
    },

    render: function () {

        this.renderWithTemplate();

        this.cacheElements({
            activateButton: '[data-hook=submit-button]',
            accountForm: '[data-hook=account-form]'
        });

        $(this.activateButton).text('Activate');
        $(this.activateButton).on('click',
            function () {
                if (this.form.valid) {
                    this.validateToken();
                }
            }.bind(this));
    },

    validateToken: function() {
        var _this = this;

        var success = function() {
            if (_this.userAuthInfo.tokenExpired) {
                app.router.trigger('page', new ErrorPage({ message: errorConstants.errorPages.linkExpired }));
            } else {
                _this.activateAccount();
            }
        };

        var error = function (model, response) {
            if (response.statusText === errorConstants.userValidation.invalidLastName) {
                _this.showFieldError(_this.form.getField('lastname'));
            }
            else if (response.statusText === errorConstants.userValidation.maxAttemptsReached)
            {
                app.router.trigger('page', new ErrorPage({ message: errorConstants.errorPages.maxVerificationAttempts }));
            }
        };

        this.getUserAuthInfo(_this.form.data.lastname, success, error);
    },

    showFieldError: function (field) {
        field.showError = true;
        field.runTests();
    },

    activateAccount: function() {
        var env = this.getQueryStringParam('env');
        var token = this.getQueryStringParam('token');

        $.ajax({
            url: __urlRoot + env + __apiRoot + '/app/v/1/newpassword',

            type: 'POST',

            headers: {
                "x-username": this.form.data.username,
                "x-newpassword": this.form.data.password,
                "x-lastname" : this.form.data.lastname,
                "x-app-type": 'DirectAccess',
                "x-token": decodeURIComponent(token)
            },

            success: function () {
                // Show success message
                app.router.trigger('page', new ActivateSuccessPage({
                    showUsernameOnSuccess: !this.showUsernameField,
                    username: this.userAuthInfo.userName
                }));
            }.bind(this),

            error: function (response) {
                if (response.statusText === undefined) {
                    response.statusText = "Failed to activate account.";
                }

                $.notify({
                        message: response.statusText
                    },
                    {
                        type: 'danger',
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });

                if (response.statusText === errorConstants.userValidation.invalidUsername ||
                    this.showUsernameField && response.statusText === errorConstants.userValidation.invalidUsernameAndLastName) {

                    this.showFieldError(this.form.getField('username'));
                }

                if (response.statusText === errorConstants.userValidation.invalidLastName ||
                    response.statusText === errorConstants.userValidation.invalidUsernameAndLastName) {

                    this.showFieldError(this.form.getField('lastname'));
                }
                
            }.bind(this)
        });
    }
});