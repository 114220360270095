var templates = require('../../templates');

// Views
var View = require('ampersand-view');
var LoadingView = require('../loading-view');
var SecurityQuestionsView = require('./mfa-security-questions-view');

// Helpers
var ajax = require('../../helpers/ajax');
require('../../../vendor/bootstrap-pincode-input');

// Constants
var LOADING_VIEW_COLOR = '#d3d3d3';
module.exports = View.extend({
    template: templates.mfaSetup.offlineDeviceSetup,
    props: {
        success: 'boolean',
        isVerifying: 'boolean',
        showIndicator: 'boolean'
    },
    bindings: {
        'success': [
            {
                type: 'booleanClass',
                hook: 'input-indicator',
                yes: ['fa-check-circle', 'success-font'],
                no: ['fa-times-circle', 'fail-font']
            },
            {
                type: 'booleanAttribute',
                hook: 'next-button',
                name: 'disabled',
                invert: true
            }
        ],
        'showIndicator': {
            type: 'toggle',
            hook: 'input-indicator'
        },
        'isVerifying': [
            {
                type: 'toggle',
                hook: 'auth-input-loading-view'
            },
            {
                //DESDP-15799 Disable the inputs when verifying
                type: function(pincodeContainer, isVerifying) {
                    if (isVerifying) {
                        $(pincodeContainer).find('input').attr('disabled', 'disabled');
                    }
                },
                hook: 'pincode-container'
            }
        ]
    },
    events: {
        'click [data-hook=next-button]': 'goToNextPage'
    },
    initialize: function () {
        this.renderWithTemplate();

        this.cacheElements({
            authInput: '[data-hook=auth-input]',
            qrCodeImage: '[data-hook=qr-code-image]',
            qrCodeString: '[data-hook=qr-code-string]',
            qrLoadingViewContainer: '[data-hook=qr-loading-view]',
            authInputLoadingViewContainer: '[data-hook=auth-input-loading-view]',
            inputIndicator: '[data-hook=input-indicator]',
            backButton: '[data-hook=back-button]',
            nextButton: '[data-hook=next-button]', // TODO set handler for next button to go to security questions
            pincodeContainer: '[data-hook=pincode-container]'
        });

        this.success = false;
        this.isVerifying = false;
        this.showIndicator = false;
    },
    render: function() {
        // Hide app links at the bottom
        this.parent.showAppLinks(false);

        // Display input as a pincode input
        $(this.authInput).pincodeInput({
            inputs: 6,
            hidedigits: false,
            change: this.updateAuthInput.bind(this),
            complete: this.verifyAuthInput.bind(this)
        });

        // Show loading view
        var qrLoadingView = new LoadingView({
            target: this.qrLoadingViewContainer,
            color: LOADING_VIEW_COLOR
        });

        // Hide loading after the QR image has finished loading
        var image = $(this.qrCodeImage);
        image.load(function() {
            qrLoadingView.hide();
        });

        // Render QR code
        var success = function(model) {
            image.attr('src', model.image);
            $(this.qrCodeString).text(model.code);
        }.bind(this);

        ajax.post('/app/v/1/mfa/createqrcode', success, null, state.username);

        // Add event handlers
        $(this.backButton).click(function () {
            this.parent.trigger('offlineSetupView:clicked:back');
        }.bind(this));
    },
    updateAuthInput: function () {
        var input = $(this.authInput);
        if (input.val().length !== 6) {
            this.showIndicator = false;
            this.success = false;
        }
    },
    verifyAuthInput: function () {
        var success = function (response) {
            if (response.Result) {
                this.passAuth(response);
            } else {
                this.failAuth();
            }
        }.bind(this);

        var error = function() {
            this.failAuth();
        }.bind(this);

        // Display loading spinner
        if (!this.loadingView) {
            this.loadingView = new LoadingView({
                target: this.authInputLoadingViewContainer,
                color: LOADING_VIEW_COLOR
            });
        }

        // Verify input
        this.isVerifying = true;
        ajax.post('/app/v/1/mfa/authoffline', success, error, {
            userName: state.username,
            timeBasedToken: $(this.authInput).val()
        });
    },
    goToNextPage: function () {
        this.securityQuestionsView = new SecurityQuestionsView();
        this.parent.switchView(this.securityQuestionsView);
    },
    passAuth: function (response) {
        app.notifyParentPage({ mfaToken: response.MfaToken });
        this.success = true;
        this.showIndicator = true;
        this.isVerifying = false;
        // DESDP-15799 Disable the pin code inputs when the user passes
        $(this.pincodeContainer).find('input').attr('disabled', 'disabled');
    },
    failAuth: function () {
        this.success = false;
        this.showIndicator = true;
        this.isVerifying = false;
        // DESDP-15799 Reenable the pin code inputs when the user fails
        $(this.pincodeContainer).find('input').removeAttr('disabled');
    }
});