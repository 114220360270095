// This object is used to store state across multiple tabs
// in a browser. This is required so that we can maintain 
// a user logged into the application on multiple tabs
var State = require('ampersand-state');
var log = require('bows')("state.js");
var STORAGE_KEY = 'directaccess';

module.exports = State.extend({
    props: {
        environment: ['string', false, ""],
        username: ['string', false, ""],
        isLoggedIn: {
            type: 'boolean',
            default : false
        },
        passedMfaAuth: {
            type: 'boolean',
            default: false
        },
        loggedInTabIds: {
            type: 'array',
            default: function () { return []; }
        },
        hasSkippedMfaSetup: {
            type: 'boolean',
            default: false
        },
        isEmployee: {
            type: 'boolean',
            default: true
        },
        // This is used in DirectAccess.Web to determine whether MFA options should show
        requiresMfa: {
            type: 'boolean',
            default: false
        },
        enableAccessibility: ['boolean', false, false]
    },
    initialize: function () {
        var _this = this;

        // Populate the cross tab properties with anything
        // already stored in localstorage
        _this.readFromLocalStorage();
    },
    readFromLocalStorage: function() {
        var existingData = localStorage[STORAGE_KEY];
        if (existingData) {
            this.set(JSON.parse(existingData));
        }
    },
    writeToLocalStorage: function () {
        log('writing to storage ' + JSON.stringify(this));
        localStorage[STORAGE_KEY] = JSON.stringify(this);
    },
    logIn: function(username, environment) {
        this.username = username;
        this.environment = environment;
        this.isLoggedIn = true;

        this.writeToLocalStorage();
    },
    getUrlRoot: function () {
        var url = __urlRoot;

        if (this.environment)
        {
            url += this.environment + '/';
        }

        return url;
    },
    updateMfaSkipStatus: function(status) {
        this.hasSkippedMfaSetup = status;
        this.writeToLocalStorage();
    },
    updateMfaAuthStatus: function(status) {
        this.passedMfaAuth = status;
        this.writeToLocalStorage();
    },
    updateMfaEmployeeStatus: function (status) {
        this.isEmployee = status;
        this.writeToLocalStorage();
    },
    updateMfaRequiredStatus: function (status) {
        this.requiresMfa = status;
        this.writeToLocalStorage();
    },
    getImitatorUsername: function () {
        return this.username.split('=>')[0];
    },
    getImitatedUsername: function () {
        return this.username.split('=>')[1];
    }
});
