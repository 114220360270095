var templates = require('../../templates');
var CheckboxView = require('ampersand-checkbox-view');
var _ = require('underscore');

module.exports = CheckboxView.extend({
    initialize: function (opts) {
        CheckboxView.prototype.initialize.call(this, opts);
        this.requiredMessage = opts.requiredMessage || 'This field is required.';
    },
    props: {
        value: 'any',
        startingValue: 'any',
        displayOnSameLine: 'boolean',
        disabled: ['boolean', false, false],
        width: ['number', false, 1]
    },
    bindings: _.extend({}, CheckboxView.prototype.bindings, {
        'disabled': {
            type: 'booleanAttribute',
            selector: 'input',
            name: 'disabled'
        },
        'required': {
            type: 'booleanClass',
            name: 'input-required',
            hook: 'label'
        },
        'md': {
            type: 'class',
            hook: 'form-group'
        },
        'lg': {
            type: 'class',
            hook: 'form-group'
        },
        'displayOnSameLine': {
            type: 'switchAttribute',
            hook: 'checkbox',
            name: 'style',
            cases: {
                true: 'display:inline-block',
                false: ''
            }
        }
    }),
    derived: {
        changed: {
            deps: ['value', 'startingValue'],
            fn: function () {
                return this.value !== this.startingValue;
            }
        },
        md: {
            deps: ['width'],
            fn: function () {
                return this.width === 1 ? 'col-md-6' : 'col-md-12';
            }
        },
        lg: {
            deps: ['width'],
            fn: function () {
                return this.width === 1 ? 'col-lg-6' : 'col-lg-12';
            }
        }

    },
    // After a form has been saved we want to reset so the 
    // new value is the startingValue
    resetStartingValue: function () {
        this.startingValue = this.value;
    },
    //Handle with hover icon to see error message
    events: _.extend({}, CheckboxView.prototype.events, {
        'mouseover .fa-exclamation-circle': 'showErrorMessage',
        'mouseout .fa-exclamation-circle': 'hideErrorMessage'
    }),
    showErrorMessage: function () {
        $(this.input.parentElement).find(".alert-danger").show();
    },
    hideErrorMessage: function () {
        $(this.input.parentElement).find(".alert-danger").hide();
    }
});