var AmpersandModel = require('ampersand-model');

module.exports = AmpersandModel.extend({
    props: {
        tokenExpired: {
            type: 'boolean',
            default: true
        },
        showUserNameField: {
            type: 'boolean',
            default: false
        },
        userName: {
            type: 'string',
            default: ''
        }
    }
});