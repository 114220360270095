var View = require('ampersand-view');
var templates = require('../templates');
var log = require('bows')("loading-view.js");
var Spinner = require('spin');

var LoadingView = View.extend({
    initialize: function(options) {
        var self = this;
        options = options || {};
        this.target = options.target;

        // If no target provided then modal the whole screen
        if (!options.target) {
            $("#spinner_modal").css('display', 'block');
            options.target = $("#spinner_placeholder")[0];
            this.useFallback = true;
        }

        if (!options.color) {
            options.color = 'lightgrey';
        }

        // Create and start the spinner
        this.spinner = new Spinner({
            color: options.color,
            lines: 12,
            length: options.length,
            width: options.width,
            radius: options.radius,
            top: options.top,
            left: options.left
        });
        this.spinner.spin(options.target);

        // Setup callbacks
        if (options.waitFor) {
            this.remaining = [];

            // Listen to the sync event of each model
            options.waitFor.forEach(function(model) {
                self.remaining.push(model.id);
                self.listenTo(model, 'sync', self.syncComplete);
            });

            this.allSyncCallback = options.callback;
        }
    },
    syncComplete: function (model) {
        log('syncComplete');

        // Check if remaining. This sync call can be fired
        // off multiple times for the same model e.g. once
        // by the model and once by the collection which
        // also listens to events on the model and propogates
        var index = this.remaining.indexOf(model.id);
        if (index > -1) {
            // Remove this from the remaining list
            this.remaining.splice(index, 1);
            
            // Only call the callback if this was the last 
            // remaining
            if (this.remaining.length === 0) {
                log('allSyncComplete');

                // Hide the loading view
                this.hide();

                // Call the callback
                if (_.isFunction(this.allSyncCallback)) {
                    this.allSyncCallback(this);
                }
            }
        }
    },
    hide: function() {
        this.spinner.stop();
        if (this.useFallback) {
            $("#spinner_modal").css('display', 'none');
        }
    },
    show: function() {
        this.spinner.spin(this.target);
        if (this.useFallback) {
            $("#spinner_modal").show();
        }
    }
});

LoadingView.colours = {
    black: '#000000'
};

module.exports = LoadingView;