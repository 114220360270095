var templates = require('../../templates');

// Views
var View = require('ampersand-view');
var SelectView = require('../inputs/selectView');
var InputView = require('../inputs/inputView');
var RegistrationCompleteView = require('./mfa-registration-complete-view');

// Models
var SecurityQuestions = require('../../models/securityQuestionCollection');

// Constants
var NUMBER_OF_QUESTIONS = 5;

// Helpers
var ajax = require('../../helpers/ajax');

module.exports = View.extend({
    template: templates.mfaSetup.securityQuestions,
    events: {
       'click [data-hook=next-button]': 'nextButtonClicked'
    },
    props: {
        disableNextButton: ['boolean', false, true]
    },
    bindings: {
        'disableNextButton': {
            type: 'booleanClass',
            selector: '[data-hook=next-button]',
            name: 'disabled'
        }
    },
    render: function () {
        this.renderWithTemplate();

        this.cacheElements({
            fieldContainer: '[data-hook=field-container]'
        });


        this.fieldsArray = [];
        this.selectedQuestions = [];

        // Get all questions
        ajax.get('/app/v/1/mfa/securityquestions', this.addQuestionFields.bind(this));

        // Remove MyPay download section
        $('.app-dl-links').remove();
    },
    addQuestionFields: function (questions) {
        this.questions = new SecurityQuestions(questions);
        for (var i = 0; i < NUMBER_OF_QUESTIONS; i++) {

            // Question field
            var questionField = new SelectView({
                label: 'Question ' + (i + 1) ,
                name: 'question' + i,
                required: true,
                options: _.pluck(this.getQuestions(), 'question'),
                template: templates.inputs.selectInput(),
                width: 1,
                value: '',
                unselectedText: 'Please select a question...'
            });
            questionField.on('value:selected', function (e) {
                this.checkIfFormComplete();
                this.updateQuestionOptions(e);
            }.bind(this));
            this.renderSubview(questionField, this.fieldContainer);
            this.fieldsArray.push(questionField);

            // Answer field
            var answerField = new InputView({
                label: 'Your Answer',
                name: 'answer' + i,
                required: true,
                type: 'text',
                value: ''
            });
            answerField.on('change:value', function () {
                this.checkIfFormComplete();
            }.bind(this));
            this.renderSubview(answerField, this.fieldContainer);
            this.fieldsArray.push(answerField);
        }
    },
    checkIfFormComplete: function () {
        var hasEmptyField = false;

        // Check if there is incomplete field
        _.each(this.fieldsArray, function(field) {
            if (!field.value) {
                hasEmptyField = true;
                return;
            }
        });

        // Disable the next button if there is incomplete field
        this.disableNextButton = hasEmptyField;
    },
    updateQuestionOptions: function (e) {
        // Add selected question with according field to the list
        this.selectedQuestions = _.reject(this.selectedQuestions, function (question) {
            return question.field === e.name;
        });
        this.selectedQuestions.push({ field: e.name, question: e.value });

        // Question fields to update will be the all the question fields except the field just updated
        var questionFieldsArrayToUpdate = _.filter(this.fieldsArray, function (field) {
            return field.name !== e.name && field.name.indexOf('question') !== -1;
        });

        // Exclude questions will be the questions in selected questions
        var excludeQuestions = _.pluck(this.selectedQuestions, 'question');

        // Update options for the rest question fields
        _.each(questionFieldsArrayToUpdate, function (field) {
            var selectedQuestion = _.findWhere(this.selectedQuestions, { field: field.name });

            // The options for the fields will be the available options except the question that the current field selected
            var options = _.filter(_.pluck(this.getQuestions(), 'question'), function (question) {
                return !_.contains(excludeQuestions, question) || (selectedQuestion && question === selectedQuestion.question);
            });
            field.updateOptions(options, selectedQuestion ? selectedQuestion.question : null);
        }.bind(this));
    },
    nextButtonClicked: function () {
        var data = [];
        _.each(this.fieldsArray, function (field) {
            if (field.name.indexOf('question') !== -1) {
                var question = field.value;
                var questionId = _.findWhere(this.getQuestions(), { question: question }).questionId;
                var answerField = _.find(this.fieldsArray, function(answerField) {
                    return answerField.name === field.name.replace('question', 'answer');
                });
                data.push({
                    questionId: questionId,
                    answer: answerField.value,
                    username: state.getImitatorUsername()
                });
            }
        }.bind(this));

        var success = function() {
            this.registrationCompleteView = new RegistrationCompleteView();
            this.parent.switchView(this.registrationCompleteView);
        };
        
        var failure = function (e) {
            var notifyError = function(msg) {
                $.notify({
                        message: msg === undefined ? 'Failed to save security questions and answers.' : msg
                    },
                    {
                        type: "danger",
                        placement: {
                            from: "top",
                            align: "center"
                        }
                    });
            };
            if (app.featureToggles &&
                app.featureToggles.DisallowDuplicateSecretAnswers === "true" &&
                e && e.statusText && e.statusText.indexOf('same answer') > -1) {
                notifyError(e.statusText);
            } else {
                notifyError();
            }
        };
        ajax.put('/app/v/1/mfa/securityquestionanswers?username=' + state.username, success.bind(this), failure, data);
    },
    getQuestions: function() {
        return this.questions.map(function(questionModel) {
            return {
                questionId: questionModel.questionId,
                question: questionModel.question
            };
        });
    }
});