window.jQuery = window.$ = require('jquery');
require('bootstrap');

var dialog = require('bootbox');
var domReady = require('domready');
/* jshint ignore:start */
_ = require('underscore');
/* jshint ignore:end */

var Events = require('ampersand-events');
var MainView = require('./views/main-view');
var MyPayMainView = require('./views/mypay-main-view');
var Router = require('./router');
var StartingView = require('./views/starting-view');
var State = require('./models/state');

//Logging is disabled by default. To enable logging, set localStorage.debug = true in your console and refresh the page.
//You can leave the code in in production, and log() will just safely no-op unless localStorage.debug is set.
//Run delete localStorage.debug in console to disable the logging
var log = require('bows')("App.js");

var myPayUrl = '/mypay';

// Display popup outlining reasons for login failure
function showLoginFailDialog(req, status, error) {
    var message = req.getResponseHeader('x-status') || error.message || error;
    if (message === "Invalid username or password.") {
        message += " You can <a href=\"resetpassword\">reset your password here</a> if you forgot it.";
    }

    dialog.alert({
        title: 'Error',
        message: message
    });
    $('.submit-button').button('reset');
}

module.exports = window.app = {
    // this is the the whole app initter
    blastoff: function (config) {

        var self = this;
        app.defaultEnv = config.environment;
        if (!app.referrer) {
            app.referrer = config.referrer;
        }

        if (!window.state){
            window.state = new State();
        }

        // init our URL handlers and the history tracker
        self.router = new Router();

        // If a user is already logged in then redirect
        // to the home page
        if (state.isLoggedIn && state.passedMfaAuth) {
            app.endMfa(config.mfaToken);
            return;
        }

        // wait for document ready to render our main view
        // this ensures the document has a body, etc.
        domReady(function () {
            log("Dom is ready now!!!");

            // init our full page handler
            var startingView = self.view = new StartingView({
                el: document.body
            });

            // ...and render it
            startingView.render();

            // init our main view handler
            startingView.handleNewPage(document.URL.indexOf(myPayUrl) !== -1 ? new MyPayMainView() : new MainView());

            // Set the root to the current pathname so that it won't get rid of the iis directory (i.e. DirectAccess.Web/)
            // Wasn't an issue in bizpay because we are delimiting url from path with #
            self.router.history.start({ pushState: true, root: __urlRoot });
        });

        // Create a global event channel
        Events.createEmitter(this);
    },

    // This is how you navigate around the app.
    // this gets called by a global click handler that handles
    // all the <a> tags in the app.
    // it expects a url without a leading slash.
    // for example: "costello/settings".
    navigate: function (page) {
        var url = (page.charAt(0) === '/') ? page.slice(1) : page;

        log('navigate: ' + url);

        this.router.history.navigate(url, { trigger: true });
    },

    // Note: this is bound to the formview
    getEnvironmentAndLogin: function(data)
    {
        // Set loading state
        $('.submit-button').button('loading');

        var loginModel = this.model;
        var username = data.username;
        var password = data.password;

        // If the user has specified a fixed environment
        // then skip the environment check and call the login
        if (loginModel.environment) {
            // Strip username
            username = username.slice(username.indexOf('\\') + 1);
            app.login(username, password, loginModel.environment);
        }
        // Need to find out environment so we can call the correct login
        else {
            var success = function (data) {
                // Login with correct environment
                app.login(username, password, data);
            };

            app.getEnvironment(username, success, showLoginFailDialog);
        }
    },

    // Need to use our own xhr because we are sending
    // username and password as headers
    login: function (username, password, environment) {
        $.ajax({
            url: __urlRoot + environment + __apiRoot + '/app/v/1/login.json',
            type: 'POST',
            headers: {
                "x-username": username,
                "x-password": password,
                "x-app-type": 'DirectAccess'
            },
            success: function (res) {
                state.logIn(username, environment.toString());

                //Check if this is a trusted device, if it is, skip mfa and log in
                if (res.IsDeviceTrusted) {
                    app.endMfa(res.MfaToken);
                }

                //got to mfa setup
                app.navigate('mfaauth');
            }.bind(this),
            error: showLoginFailDialog
        });
    },

    // This function gets called after a successful login. What we are doing
    // is making sure we direct the user to the location they were going before
    // they got directed to the login screen. E.g. you get emailed a link to view a
    // payslip but you aren't logged in, after you login you want to be taken to that page
    navigateToApp: function () {

        // Holds the url from which this page was directed
        var ref = app.referrer || "";

        // Don't direct to logout or login
        if (ref !== "" && ref.indexOf('/logout') < 0 && ref.indexOf('/login') < 0) {
            // Need to split off the hostname e.g. http://www.datacomdirectaccess.co.nz this
            // is stored in origin and will be the start of the referrer
            if (ref.indexOf(document.location.origin) === 0) {
                // remove the origin and the __urlRoot (/ in live but /DirectAccess/ in local, test)
                ref = ref.slice(document.location.origin.length + __urlRoot.length);

                // Remove the environment so we use the confirmed environment that we get from login
                // If anything is left (e.g. 1/leave) then it will have an environment so we want to find the
                // next /, if it doesn't exist indexOf will return -1 and our +1 will cancel out to do nothing
                // if it does exist then we take everything from the character after (e.g. leave)
                ref = ref.slice(ref.indexOf('/') + 1);

                if (ref === 'mfasetup') {
                    app.navigate(ref);
                }
                else {
                    // Redirect to the correct page
                    window.location = state.getUrlRoot() + ref;
                }

                return;
            }
        }

        // Default case is to redirect to home
        window.location = state.getUrlRoot();
        return;
    },

    getEnvironment: function(username, success, error)
    {
        $.ajax({
            url: __urlRoot + app.defaultEnv + __apiRoot + '/app/v/1/environment',
            type: 'GET',
            headers: {
                "x-username": username,
                "x-app-type": 'DirectAccess'
            },
            success: success,
            error: error
        });
    },
    checkURLForSSO: function (config, startApp) {
        app.defaultEnv = config.environment;
        app.referrer = config.referrer;

        var success = function (data) {
            if (data) {
                var environmentInfo = data.split("|");

                var environmentId = environmentInfo[0];
                var ssoUrl = environmentInfo[1];

                app.redirectToSSO(ssoUrl, environmentId);

            } else {
                startApp();
            }
        };

        //Call companyURL api
        //If so, redirect to SSO with request URL
        //If URL not enabled for SSO, call startApp()
        $.ajax({
            url: __urlRoot + app.defaultEnv + __apiRoot + '/app/v/1/sso.json',
            type: 'GET',
            headers: {
                "x-url": window.location
            },
            dataType: "text",
            success: success,
            error: startApp
        });
    },
    redirectToSSO : function(ssoUrl, environmentId) {
        if (!window.state) {
            window.state = new State();
        }

        var http = window.location.protocol;
        var slashes = http.concat("//");
        var host = slashes.concat(window.location.host);
        var returnUrl = host.concat(state.getUrlRoot());


        var ref = document.referrer || "";
        //if referrer is not null, use that, else use default home
        if (ref === "" || ref.indexOf('logout') > -1 || ref.indexOf('login') > -1 || ref.indexOf(window.location) === -1) {
            ref = returnUrl + environmentId; //environment id
        }

        //make API call, should return sso url and environment id
        //redirect to SSO if URL is configured
        window.location = ssoUrl + "/Account/LogOn?returnurl=" + ref;
    },
    loginWithToken: function (username, token, environment, successCallBack) {
        $.ajax({
            url: __urlRoot + environment + '/api/app/v/1/login.json',
            type: 'POST',
            headers: {
                "x-username": username,
                "x-login-token": token,
                "x-app-type": 'EmbeddedPage'
            },
            success: function (res) {
                window.state = new State();
                window.state.logIn(username, environment.toString());
                if (res.IsDeviceTrusted) {
                    window.state.updateMfaAuthStatus(true);
                }
                successCallBack(res.MfaToken);
            }.bind(this),
            error: function (req, status, error) {
                dialog.alert({
                    title: 'Error',
                    message: req.getResponseHeader('x-status') || error.message || error
                });
            }
        });
    },
    endMfa: function (mfaToken) {
        state.updateMfaRequiredStatus(!state.getImitatedUsername());
        if (app.embeddedPage) {
            // Set the Mfa status to false, so that subsequent logins won't skip Mfa for embedded pages
            state.updateMfaAuthStatus(false);
            if (window.external !== undefined && window.external.endMfa !== undefined) {
                // This is only called if this page is embedded in the fat client and using Windows Forms browser engine
                window.external.endMfa();
            } else if (window.endMfa !== undefined) {
                // This is only called if this page is embedded in the fat client and using EO browser engine
                window.endMfa();
            } else {
                this.notifyParentPage({
                    action: 'endMfa',
                    mfaToken: mfaToken
                });
            }
        } else {
            app.navigateToApp();
        }
    },
    backToLogin: function() {
        if (app.embeddedPage) {
            if (window.external !== undefined && window.external.backToLogin !== undefined) {
                // This is only called if this page is embedded in the fat client and using Windows Forms browser engine
                window.external.backToLogin();
            } else if (window.backToLogin !== undefined) {
                // This is only called if this page is embedded in the fat client and using EO browser engine
                window.backToLogin();
            } else {
                // for bizpay and ess
                this.notifyParentPage({ action: 'backToLogin'});
            }
        }
    },
    notifyParentPage: function(data) {
        if (window.parent) {
            window.parent.postMessage(data, '*');
        }
    }
};
