var templates = require('../../templates');

var View = require('ampersand-view');

// Helpers
var ajax = require('../../helpers/ajax');
var systemInfoHelper = require('../../helpers/systemInfoHelper');

// Views
var CheckboxView = require('../inputs/checkboxView');

module.exports = View.extend({
    template: templates.mfaAuth.trustDevice,
    props: {
        mfaToken: ['string', true, '']
    },
    events: {
        'click [data-hook=login]' : 'completeLogin'
    },
    render: function() {
        this.renderWithTemplate();
        this.cacheElements({
            login: '[data-hook=login]',
            checkboxContainer: '[data-hook=trust-device-checkbox-container]'
        });

        if (app.featureToggles &&
            app.featureToggles.ShowUpdatedWordingForRememberMe === "true") {
            this.trustThisDeviceCheckbox = new CheckboxView({
                name: ' Remember this verification for 24 hours',
                type: 'text',
                displayOnSameLine: true
            });
        } else {
            this.trustThisDeviceCheckbox = new CheckboxView({
                name: ' Remember me for today',
                type: 'text',
                displayOnSameLine: true
            });

        }

        this.renderSubview(this.trustThisDeviceCheckbox, this.checkboxContainer);
    },
    completeLogin: function() {
        if (this.trustThisDeviceCheckbox.value) {
            var _this = this;
            var browser = systemInfoHelper.getBrowser();
            var callback = function() {
                app.endMfa(_this.mfaToken);
            };
            var data = {
                Username: state.username,
                OperatingSystem: systemInfoHelper.getOperatingSystem(),
                Browser: browser.name + ' ' + browser.version
            };
            // Log the user in even if the trusted device didn't get saved
            ajax.post('/app/v/1/mfa/savetrusteddevice', callback, callback, data);
        } else {
            app.endMfa(this.mfaToken);
        }
    }
});