var View = require('ampersand-view');
var templates = require('../../templates');

module.exports = View.extend({
    template: templates.resetpassword.success,
    events: {
        'click button': 'goToLogin'
    },
    goToLogin: function() {
        app.navigate('login');
    }
});