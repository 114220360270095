var Collection = require('ampersand-rest-collection');
var AjaxHelper = require('../helpers/ajaxHelper');

module.exports = Collection.extend({
    ajaxConfig: function () {
        return {
            headers: AjaxHelper.getHeaders()
        };
    },
    fetch: function (options) {

        // Cater for undefined options
        options = options || {};

        var error = options.error;
        options.error = function (collection, response, options) {
            AjaxHelper.errorWrapper(collection, response, options, error);
        };

        // Default timeout to 30 seconds
        options.timeout = options.timeout || 30000;

        Collection.prototype.fetch.call(this, options);
    }
});