var FormView = require('ampersand-form-view');
var View = require('ampersand-view');
var InputView = require('ampersand-input-view');
var NewPasswordSuccessView = require('./newPasswordSuccess.js');
var templates = require('../../templates');
var dialog = require('Bootbox');
var _ = require('underscore');

//====================================
//Set the input view template
//====================================
var ExtendedInput = InputView.extend({
    template: templates.includes.formInput()
});

var Form = FormView.extend({
    //That form is given an array of field views, which are input views
    fields: function () {
        return [
            new ExtendedInput({
                label: '',
                name: 'password',
                required: true,
                requiredMessage: 'New password is required',
                type: 'password',
                placeholder: 'New Password',
                parent: this,
                tests: [
                    function (val) {
                        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^*()+=]).{8,}$/;
                        if (!re.test(val)) {
                            var errorMsg = "Your new password must be at least 8 characters long, " +
                                    "and contains at least 1 character from each of the following 4 groups: " +
                                    "lower case letters (a-z), upper case letters (A-Z), numbers (0-9), and symbols (!@#$%^*()+=). ";
                            return errorMsg;
                        }
                    }
                ]
            }),
            // Confirm the password
            new ExtendedInput({
                label: '',
                name: 'passwordConfirmed',
                required: true,
                requiredMessage: 'Please enter the same password',
                type: 'password',
                placeholder: 'Confirm Password',
                parent: this,
                tests: [
                    function(val) {
                        if (val !== document.getElementsByName('password')[0].value) {
                            return "Please enter same password";
                        }
                    }
                ]
            })
        ];
    }
});

var saveNewPassword = function (data) {
    // Switch to working state
    $('.submit-button').button('loading');

    var username = this.config.username;
    var password = data.password;
    var token = this.config.token;
    var onboard = this.config.onboard;

    function error(req, status, error) {
        dialog.alert({
            title: 'Error',
            message: req.getResponseHeader('x-status') || error.message || error
        });
        $('.submit-button').button('reset');
    }

    function savePassword(environment, status, req) {
        if (app.featureToggles &&
            app.featureToggles.PasswordResetPageSecurityFix === "true" &&
            isNaN(environment)) {
            error();
        } else {
            $.ajax({
                url: __urlRoot + environment + __apiRoot + '/app/v/1/newpassword',
                type: 'POST',
                headers: {
                    "x-username": username,
                    "x-newpassword": password,
                    "x-app-type": 'DirectAccess',
                    "x-token": token
                },
                success: function(data, status, req) {
                    // Show success message
                    var config = {
                        onboard: onboard
                    };
                    app.router.trigger('page', new NewPasswordSuccessView(config));
                },
                error: error
            });
        }
    }

    // If the enviroment has been passed in when creating the form,
    // which is the case for reset password link, then use that 
    // otherwise make a request to ge it.
    if (this.config.environment) {
        savePassword(this.config.environment);
    } else {
        app.getEnvironment(username, savePassword, error);
    }
};

module.exports = View.extend({
    template: templates.newpassword.form,
    initialize: function (config) {
        // Navigation to this page will be passed some information
        // username, token etc
        this.config = config;
    },

    render: function() {
        this.renderWithTemplate({ username: this.config.username });
    },

    subviews: {
        form: {
            container: '[data-hook=newpassword-form]',
            prepareView: function (el) {
                return new Form({
                    el: el,
                    submitCallback: _.bind(saveNewPassword, this)
                });
            }
        }
    }
});

