var templates = require('../../templates');

var View = require('ampersand-view');

// Helpers
var ajax = require('../../helpers/ajax');
var systemInfoHelper = require('../../helpers/systemInfoHelper');
var ip = require('ip');

// Views
var LoadingView = require('../loading-view');

//Constants
var POLL_INTERVAL = 5000;
var SESSION_EXPIRED_TIME = 300000;
var LOADING_VIEW_COLOR = '#d3d3d3';

module.exports = View.extend({
    template: templates.mfaAuth.onlineAuth,
    events: {
        'click [data-hook=resend]': 'sendAuth'
    },
    props: {
        sessionExpired: ['boolean', false, false],
        isSetup: ['boolean', true, false],
        hasApproved: ['boolean', true, false],
        hasSetupSecurityQuestions: ['boolean', true, true]
    },
    bindings: {
        'sessionExpired': [
            {
                //Show the resend box if session is expired and they haven't approved yet
                type: function(resendBox, expired) {
                    if (expired && !this.hasApproved) {
                        $(resendBox).show();
                        $(this.authInstruction).hide();
                    } else {
                        $(resendBox).hide();
                        $(this.authInstruction).show();
                    }
                },
                hook: 'resend-section'
            },
            {
                //Show the loading spinner if the session has not expired and the user hasn't approved the request
                type: function(indicatorContainer, expired) {
                    if (!expired && !this.hasApproved) {
                        if (this.loadingView) {
                            this.loadingView.show();
                        } else {
                            this.loadingView = new LoadingView({
                                target: indicatorContainer,
                                color: LOADING_VIEW_COLOR,
                                length: 5,
                                width: 3.5,
                                radius: 6.67,
                                left: 2,
                                top: 2
                            });
                        }
                        $(this.indicator).hide();
                    } else {
                        this.loadingView.hide();
                        $(this.indicator).show();
                    }
                },
                hook: 'input-indicator-container'
            }
        ],
        //If this view isn't in the setup page, it needs some css to make it smaller
        'isSetup': [
            {
                type: 'booleanClass',
                selector: '[data-hook=auth-view]',
                name: ['col-sm-8', 'col-sm-offset-2'],
                invert: true
            },
            //Turn off the indicator for the login page
            {
                type: 'toggle',
                selector: '[data-hook=input-indicator-container]'
            }
        ],
        'hasApproved': [
            //Show the indicator instead of the spinner when approved or when session has expired
            {
                type: function(indicator, hasApproved) {
                    if (hasApproved || (this.sessionExpired)) {
                        $(indicator).show();
                        if (this.loadingView) {
                            this.loadingView.hide();
                        }
                    } else {
                        $(indicator).hide();
                    }
                },
                hook: 'input-indicator'
            },
            //Determine the style of the indicator
            {
                type: 'booleanClass',
                selector: '[data-hook=input-indicator]',
                yes: ['fa-check-circle', 'success-font'],
                no: ['fa-times-circle', 'fail-font']
            },
            //When the user approves, the text should be replaced with a message of approval
            {
                type: 'toggle',
                selector: '[data-hook=approved-text]'
            },
            {
                type: 'toggle',
                selector: '[data-hook=authentication-instruction]',
                invert: true
            }
        ]
    },
    render: function () {
        this.renderWithTemplate();
        this.cacheElements({
            authInstruction: '[data-hook=authentication-instruction]',
            authTitle: '[data-hook=online-auth-title]',
            indicatorContainer: '[data-hook=input-indicator-container]',
            indicator: '[data-hook=input-indicator]'
        });
        if (this.isSetup) {
            $(this.authTitle).text('Step 2. Waiting for your approval');
        }
        this.timeElapsed = 0;
        this.sendAuth();
    },
    sendAuth: function () {
        this.sessionExpired = false;
        this.myPayAuth();

        setTimeout(function () {
            this.sessionExpired = true;
        }.bind(this), SESSION_EXPIRED_TIME);
    },
    myPayAuth: function () {
        var formData = {
            userCode: state.username,
            browser: systemInfoHelper.getBrowser().name,
            operatingSystem: systemInfoHelper.getOperatingSystem(),
            iPAddress: ip.address()
        };

        var success = function (data) {
            this.pollForAuthCheck(data.code);
        }.bind(this);

        var failure = function () {
            // TODO: handle failure
        };
        if (app.sourceApp) {
            ajax.post('/app/v/1/mfa/authweb?sourceApp='+app.sourceApp, success, failure, formData);
        } else {
            ajax.post('/app/v/1/mfa/authweb?sourceApp=DirectAccess', success, failure, formData);
        }
    },
    pollForAuthCheck: function (otp) {
        var _this = this;

        if (_this.hasApproved) {
            return;
        }

        var formData = {
            userCode: state.username,
            otp: otp
        };

        var success = function (response) {
            if (_this.isSetup) {
                app.notifyParentPage({ mfaToken: response.MfaToken });
                _this.trigger('authenticationApproved');
                _this.hasApproved = true;
            } else {
                if (!_this.hasSetupSecurityQuestions) {
                    app.notifyParentPage({ mfaToken: response.MfaToken });
                    app.navigate('mfasetupsecurityquestions');
                } else {
                    _this.parent.renderTrustThisDeviceView(response.MfaToken);
                }
            }
        };

        var failure = function () {
            if (!this.sessionExpired) {
                setTimeout(function () { _this.pollForAuthCheck(otp); }, POLL_INTERVAL);
            }
        }.bind(this);

        ajax.post('/app/v/1/mfa/authcheckweb', success, failure, formData);
    },
    stopPolling: function() {
        this.hasApproved = true;
    }
});