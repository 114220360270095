var FormView = require('ampersand-form-view');
var Input = require('./input-view');
var PageView = require('./mypay-base');
var ResetPasswordSuccessPage = require('./reset-password-success-page');
var ErrorPage = require('./error-page');
var templates = require('../../templates');
var UserAuthInfo = require('../../models/userAuthInfo');
var errorConstants = require('../../helpers/constants/error-constants');

// Form view
var ResetPasswordForm = FormView.extend({
    fields: function () {
        return [
            new Input({
                label: 'New Password',
                name: 'newpassword',
                value: '',
                required: true,
                requiredMessage: 'Password is required',
                type: 'password',
                parent: this,
                hasHelp: true,
                helpMessage: 'Your password must be at least eight characters long, and contain at least one character from each of the following four groups:<br>- lower case letters (a-z)<br>- upper case letters (A-Z)<br>- numbers (0-9)<br>- symbols (!@#$%^*()+=)',
                tests: [
                    function (val) {
                        this.parent.getField('confirmpassword').checkError();

                        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^*()+=]).{8,}$/;
                        if (!re.test(val)) {
                            var errorMsg = "Your password doesn't meet requirements.";
                            return errorMsg;
                        }
                    }
                ]
            }),
            new Input({
                label: 'Confirm Password',
                name: 'confirmpassword',
                value: '',
                required: true,
                requiredMessage: 'Password is required',
                type: 'password',
                parent: this,
                tests: [
                    function(val) {
                        if (val !== document.getElementsByName('newpassword')[0].value) {
                            return "Please enter same password";
                        }
                    }
                ]
            })
        ];
    }
});

// Page view
module.exports = PageView.extend({
    pageTitle: 'Reset Your Password',
    template: templates.myPayAccount.formPage,
    subviews: {
        form: {
            container: '[data-hook=account-form]',
            prepareView: function (el) {
                this.form = new ResetPasswordForm({
                    el: el
                });
                return this.form;
            }
        }
    },
    
    render: function() {
        this.renderWithTemplate();
       
        this.cacheElements({
            resetButton: '[data-hook=submit-button]',
            accountForm: '[data-hook=account-form]'
        });

        $(this.resetButton).text('Reset');
        $(this.resetButton).on('click',
            function () {
                this.resetPassword();
            }.bind(this));
    },

    initialize: function () {
        this.userAuthInfo = new UserAuthInfo();
        this.validateUrl();
    },
    
    resetPassword: function() {
        if (this.form.valid) {
            var env = this.getQueryStringParam('env');
            var token = this.getQueryStringParam('token');

            $.ajax({
                url: __urlRoot + env + __apiRoot + '/app/v/1/users/setpassword/',
                type: 'PUT',
                headers: {
                    'x-app-type': 'DirectAccess'       
                },
                data: JSON.stringify({
                    token: decodeURIComponent(token),
                    password: this.form.getValue('newpassword')
                }),
 
                success: function () {
                    app.router.trigger('page', new ResetPasswordSuccessPage());
                },
 
                error: function(response) {                    
                    if (response.statusText === undefined) {
                        response.statusText = 'Failed to reset password.';
                    }
                    
                    if (response.statusText === 'Max number of password reset attempts reached.') {
                        app.router.trigger('page', new ErrorPage({ message: errorConstants.errorPages.maxPasswordResetAttempts }));
                    }
                    else
                    {                    
                        $.notify({
                                message: response.statusText
                            },
                            {
                                type: 'danger',
                                placement: {
                                    from: 'top',
                                    align: 'center'
                                }
                            });
                    }
                }.bind(this)
            });
        }
    }
});

