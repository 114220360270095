var templates = require('../../templates');

// Views
var View = require('ampersand-view');
var InputView = require('../inputs/inputView');
var LoadingView = require('../loading-view');

// Models
var SecurityQuestions = require('../../models/securityQuestionCollection');

// Constants
var LOADING_VIEW_COLOR = '#d3d3d3';

// Helpers
var ajax = require('../../helpers/ajax');

module.exports = View.extend({
    template: templates.mfaAuth.securityQuestionsAuth,
    events: {
       'click [data-hook=login-button]': 'loginButtonClicked'
    },
    props: {
        disableLoginButton: ['boolean', false, true],
        alertMessageText: ["string", true, ""],
        disableInputs: ['boolean', false, false],
        isVerifying: ["boolean", true, false]
    },
    bindings: {
        'disableLoginButton': {
            type: 'booleanClass',
            selector: '[data-hook=login-button]',
            name: 'disabled'
        },
        'alertMessageText': {
            type: "text",
            hook: "alert-message"
        },
        'showMessage': {
            type: "toggle",
            hook: "alert-message"
        },
        'disableInputs': {
            type: 'booleanAttribute',
            selector: '.form-control',
            name: 'disabled'
        },
        'isVerifying': [
            {
                type: "toggle",
                hook: "auth-input-loading-view"
            },
        ]
    },
    derived: {
        'showMessage':{
            deps: ['alertMessageText'],
            fn: function() {
                return this.alertMessageText !== "";
            }
        }
    },
    render: function () {
        this.renderWithTemplate();

        this.cacheElements({
            formContainer: '[data-hook=form-container]',
            alertMessage: "[data-hook=alert-message]",
            authInputLoadingViewContainer: "[data-hook=auth-input-loading-view]"
        });

        this.loadingView = new LoadingView({
            target: this.authInputLoadingViewContainer,
            color: LOADING_VIEW_COLOR
        });

        this.alertMessageText = "";
        this.inputsArray = [];
        this.selectedQuestions = [];

        // Get questions
        ajax.get('/app/v/1/mfa/securityquestions?username=' + state.username, this.addinputs.bind(this));
    },
    addinputs: function (questions) {
        this.questions = new SecurityQuestions(questions);
        _.each(this.questions.models, function(question) {
            // Populate inputs
            var input = new InputView({
                label: question.question,
                required: true,
                name: question.questionId.toString(),
                type: 'text',
                value: ''
            });

            input.on('change:value', function () {
                this.checkIfFormComplete();
            }.bind(this));

            this.renderSubview(input, this.formContainer);
            this.inputsArray.push(input);
        }.bind(this));
    },
    checkIfFormComplete: function () {
        var hasEmptyInput = false;

        // Check if there is incomplete input
        _.each(this.inputsArray, function(input) {
            if (!input.value) {
                hasEmptyInput = true;
                return;
            }
        });

        // Disable the login button if there is incomplete input
        this.disableLoginButton = hasEmptyInput;
    },
    loginButtonClicked: function () {
        var data = [];
        _.each(this.inputsArray, function (input) {
            data.push({
                questionId: parseInt(input.name),
                answer: input.value,
                username: state.username
            });
        }.bind(this));

        var success = function(response) {
            this.isVerifying = false;
            if (response.Result){
                app.endMfa(response.MfaToken);
            }
            else {
                this.alertMessageText = "Answers provided are invalid.";
            }
        };

        var failure = function(req, status, error) {
            this.alertMessageText = error;
            this.disableInputs = true;
            this.isVerifying = false;
        }.bind(this);

        if (!this.disableLoginButton){
            ajax.post('/app/v/1/mfa/authsecurityanswers', success.bind(this), failure, data);
            this.isVerifying = true;
            this.disableLoginButton = true;
        }
    },
    getQuestions: function() {
        return this.questions.map(function(questionModel) {
            return {
                questionId: questionModel.questionId,
                question: questionModel.question
            };
        });
    }
});