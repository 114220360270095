var ajax = require('../../helpers/ajax');
var templates = require('../../templates');

var OfflineAuthView = require('./mfa-offline-auth-view');
var OnlineAuthView = require('./mfa-online-auth-view');
var SecurityQuestionsView = require('./mfa-security-questions-auth-view');
var TrustThisDeviceView = require('./mfa-trust-this-device-view');
var View = require('ampersand-view');
var ViewSwitcher = require('ampersand-view-switcher');

// COnstants
var ONLINE_AUTH = 'online';
var OFFLINE_AUTH = 'offline';
var BOTH_AUTH = 'both';

module.exports = View.extend({
    template: templates.mfaAuth.main,
    events: {
        'click [data-hook=back-button]': 'backToLogin',
        'click [data-hook=security-questions-option]': 'goToSecurityQuestions'
    },
    initialize: function () {
        this.checkUserMfaSetup();
    },
    render: function () {
        this.renderWithTemplate();

        this.cacheElements({
            authView: '[data-hook=auth-content]',
            loadingOverlay: '[data-hook=loading-overlay]',
            backButton: '[data-hook=back-button]',
            secondAuthView: '[data-hook=auth-secondary-content]',
            securityQuestionButton: '[data-hook=security-questions-option]'
        });

        this.viewSwitcher = new ViewSwitcher(this.authView, {
            show: _.bind(function (view) {
                this.registerSubview(view);
            }, this)
        });
        this.renderView();
    },
    renderView: function() {
        if (this.status === ONLINE_AUTH) {
            // Default to the setup type selection page
            this.authenticationSubView = new OnlineAuthView({
                parent: this,
                sessionExpired: false,
                hasSetupSecurityQuestions: this.hasSetupSecurityQuestions
            });

        } else if (this.status === OFFLINE_AUTH) {
            this.authenticationSubView = new OfflineAuthView({
                parent: this,
                sessionExpired: false,
                hasSetupSecurityQuestions: this.hasSetupSecurityQuestions
            });
        } else if (this.status === BOTH_AUTH) {
            this.authenticationSubView = new OnlineAuthView({
                parent: this,
                sessionExpired: false,
                hasSetupSecurityQuestions: this.hasSetupSecurityQuestions
            });
            this.authSecondSubView = new OfflineAuthView({
                parent: this,
                sessionExpired: false,
                isSecondAuth: true,
                hasSetupSecurityQuestions: this.hasSetupSecurityQuestions
            });
            this.secondViewSwitcher = new ViewSwitcher(this.secondAuthView, {
                show: _.bind(function (view) {
                    this.registerSubview(view);
                }, this)
            });
        }

        if (this.authenticationSubView) {
            this.authenticationSubView.render();
            this.viewSwitcher.set(this.authenticationSubView);
            $(this.loadingOverlay).hide();
            if (app.embeddedPage) {
                // If this is an embedded page, call the backToLogin function in app.js
                this.backToLogin = function() {
                    app.backToLogin();
                };
            }
        }
        if (this.authSecondSubView) {
            this.authSecondSubView.render();
            this.secondViewSwitcher.set(this.authSecondSubView);
        }
    },
    backToLogin: function () {
        window.location.replace(__urlRoot);
    },
    goToSecurityQuestions: function() {
        $(this.securityQuestionButton).hide();
        if (this.secondAuthView) {
            $(this.secondAuthView).hide();
        }

        this.securityQuestionsView = new SecurityQuestionsView({
            parent: this,
            sessionExpired: false,
            isSecondAuth: true
        });

        this.securityQuestionsView.render();
        this.viewSwitcher.set(this.securityQuestionsView);
    },
    checkUserMfaSetup: function () {
        var _this = this;
        var success = function (userSetupData) {
            if (userSetupData.requires2FA) {
                state.updateMfaEmployeeStatus(userSetupData.isEmployee);
                _this.hasSetupSecurityQuestions = userSetupData.hasSetupSecurityQuestions;
                if (userSetupData.isFirstSetup) {
                    state.updateMfaSkipStatus(false);
                    app.navigate('mfasetup');
                } else if (userSetupData.isSetupRequired) {
                    //Need to update and cache the state in case the user navigates away and returns
                    state.updateMfaSkipStatus(true);
                    app.navigate('mfasetup');
                } else if (userSetupData.hasRegisteredDevice && userSetupData.hasSetupAuthenticator) {
                    $(_this.parent.appLinks).hide();
                    _this.status = BOTH_AUTH;
                    _this.render();
                } else if (userSetupData.hasRegisteredDevice) {
                    $(_this.parent.appLinks).hide();
                    _this.status = ONLINE_AUTH;
                    _this.render();
                } else if (userSetupData.hasSetupAuthenticator) {
                    $(_this.parent.appLinks).hide();
                    _this.status = OFFLINE_AUTH;
                    _this.render();
                }
            } else {
                app.navigateToApp();
            }
        };

        ajax.get('/app/v/1/mfa/setup?username=' + state.username, success);
    },
    renderTrustThisDeviceView: function (mfaToken) {
        $(this.backButton).hide();
        $(this.securityQuestionButton).hide();
        if (this.authenticationSubView && (this.status === ONLINE_AUTH || this.status === BOTH_AUTH)) {
            this.authenticationSubView.stopPolling();
        }
        this.trustThisDeviceView = new TrustThisDeviceView({
            parent: this,
            mfaToken: mfaToken
        });
        this.trustThisDeviceView.render();
        this.viewSwitcher.set(this.trustThisDeviceView);

        if (this.secondViewSwitcher) {
            this.secondViewSwitcher.clear();
        }
    }
});