var _ = require('underscore');

// The colour variables are global variables in CSS that decalred DirectAccess.Login/Content/less/colors.less
var accessibleColourNames = [
    '--sky-blue',
    '--status-red',
    '--danger-red',
    '--success-green',
    '--dark-gray'
];

// Get variable names with original values
var accessibleOriginalColours = _.map(accessibleColourNames, function (name) {
    return { name: name, value: getComputedStyle(document.documentElement).getPropertyValue(name) };
});

// Darken RGB values by 80 each
var COLOUR_ADJUST_AMOUNT = -80;

var accessibilityHelper = {
    adjustColourContrast: function () {
        // Darken each colours if accessibility is enabled or revert colours if accessibility mode is off
        _.each(accessibleColourNames, function (colourName) {
            var originalColour = _.findWhere(accessibleOriginalColours, { name: colourName }).value;
            var adjustToColour = state.enableAccessibility ? this.adjustColour(originalColour, COLOUR_ADJUST_AMOUNT) : originalColour;
            document.documentElement.style.setProperty(colourName, adjustToColour);
        }.bind(this));
    },
    adjustColour: function (h, amount) {
        var r = parseInt("0x" + h[1] + h[2]) + amount;
        var g = parseInt("0x" + h[3] + h[4]) + amount;
        var b = parseInt("0x" + h[5] + h[6]) + amount;
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    componentToHex: function (c) {
        // RGB value range is from 0 - 255
        if (c > 255) {
            c = 255;
        } else if (c < 0) {
            c = 0;
        }
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    },
    enableCompatibilityForIE: function () {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js";
        document.getElementsByTagName("head")[0].appendChild(script);
    }
};

module.exports = accessibilityHelper;