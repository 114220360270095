var View = require('ampersand-view');
var ErrorPage = require('./error-page');
var errorConstants = require('./../../helpers/constants/error-constants');

module.exports = View.extend({

    validateUrl: function () {
        var _this = this;

        var success = function() {
            if (_this.userAuthInfo.tokenExpired) {
                app.router.trigger('page', new ErrorPage({ message: errorConstants.errorPages.linkExpired }));
            } else {
                $(_this.accountForm).show();
                _this.showUsernameField = _this.userAuthInfo.showUserNameField;
                _this.model = _this.userAuthInfo;
            }
        };

        var error = function() {
            app.router.trigger('page', new ErrorPage({ message: errorConstants.errorPages.linkInvalid }));
        };

        this.getUserAuthInfo(null, success, error);
    },

    getUserAuthInfo: function(lastName, success, error) {
        var token = this.getQueryStringParam('token');
        var env = this.getQueryStringParam('env');

        var lastNameParameter = lastName !== null ? '&lastname=' + lastName : '';

        this.userAuthInfo.fetch({
            url: __urlRoot + env + __apiRoot + '/app/v/1/userauthinfo?token=' + token + lastNameParameter,
            headers: {
                "x-app-type": 'DirectAccess'
            },
            success: success,
            error: error,
            // Timeout is defaulting to 5 seconds which is a bit short so making this 1 minute
            timeout: 60000
        });
    },

    getQueryStringParam: function (name) {
        // Find the first occurrence of parameter name (either the first one ?name= or any other &name=)
        var regex = new RegExp("[?,&]" + name + "=");
        var match = location.search.match(regex);

        if (match) {
            // If a match is found take a substring from the end of the param name to the start of the next one
            var endOfCurrent = match.index + match[0].length;
            var startOfNext = location.search.indexOf('&', endOfCurrent);

            // If the start of next is not found (i.e. -1) just go to the end of the string
            return location.search.substring(endOfCurrent, startOfNext === -1 ? location.search.length : startOfNext);
        }

        return null;
    }
});
