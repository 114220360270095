var InputView = require('ampersand-input-view');
var templates = require('../../templates');
var _ = require('underscore');

module.exports = InputView.extend({
    template: templates.myPayAccount.formInput(),
    props: {
        hasHelp: ['boolean', false, false],
        helpMessage: ['string', '', ''],
        visibility: ['boolean', true, true],
        showError: ['boolean', false, false]
    },
    bindings: _.extend({}, InputView.prototype.bindings, {
        'showMessage': [{
            type: 'toggle',
            hook: 'error-message'
        },
        {
            type: 'booleanClass',
            selector: 'input',
            name: 'input-invalid',
            yes: 'input-invalid',
            no: 'input-valid'
        }],
        'message': {
            type: 'text',
            hook: 'error-message'
        },
        // Help indicator next to label
        'hasHelp': {
            type: 'toggle',
            hook: 'help-icon'
        },
        // Help message on tooltip
        'helpMessage': {
            type: 'attribute',
            name: 'title',
            hook: 'help-icon'

        },
        // Visibility
        'visibility': {
            type: 'toggle',
            hook: 'input-field'
        }
    }),

    render:  function() {
        InputView.prototype.render.apply(this, arguments);
        this.cacheElements({
            helpIcon: '[data-hook=help-icon]'
        });
        $(this.helpIcon).tooltip({html: true});
    },

    handleInputChanged: function () {
        // Override handle input changed so we can clear our forced error when the user
        //  changes the input
        this.showError = false;

        if (document.activeElement === this.input) {
            this.directlyEdited = true;
        }
        this.inputValue = this.clean(this.input.value);
    },

    // To trigger an update of the error state for a field we need to change the field value
    //  and then change it back. Calling runTests() is not sufficient as it will only show the error message 
    //  not set the 'valid' state
    checkError: function() {
        var currentValue = this.value;
        this.setValue('');
        this.setValue(currentValue);
    }
});