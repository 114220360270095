module.exports = {
    userValidation: {
        invalidUsername: 'Username is unavailable.',
        invalidLastName: 'Last name is invalid.',
        invalidUsernameAndLastName: 'Username is unavailable and last name entered is invalid.',
        maxAttemptsReached: 'Max verification attempts reached.'
    },
    errorPages: {
        linkExpired: 'The link has expired. <br/>Please contact your payroll administrator.',
        maxVerificationAttempts: 'You have exceeded the maximum verification attempts. <br/>Please contact your payroll administrator.',
        linkInvalid: 'Either your token is invalid or your account has already been activated. <br/>Please use your existing credentials to log in, or if you did not activate your account, contact your payroll administrator.',
        maxPasswordResetAttempts: 'You have exceeded the maximum password reset attempts. <br/>Please contact your payroll administrator.'
    }
};