var AmpersandModel = require('ampersand-model');

// Valid environment 'domain' entries and the 
// env ids they correspond to
var envMap = {
    1: 1, 'easipay': 1,
    2: 2, 'datapay': 2,
    3: 3, 'datacom': 3,
    4: 4, 'demo': 4,
    5: 5, 'training': 5,
	10: 10, 'australia': 10,
    11: 11, 'australia_datapay': 11,
    14: 14, 'australia_datacom': 14,
    15: 15, 'enterprise': 15,
	30: 30, 'smartpayroll': 30,
	34: 34, 'australia_demo': 34,
    35: 35, 'australia_bureau': 35,
    74: 74, 'azure_demo': 74,
    90: 90, 'azure_smartpayroll': 90
};

var envTextMap = {
    1: 'EasiPay',
    2: 'DataPay',
    3: 'Datacom',
    4: 'Demo',
    5: 'Training',
    10: 'Australia',
    11: 'Australia DataPay',
    14: 'Australia Datacom',
    15: 'Enterprise',
    30: 'SmartPayroll',
    34: 'Australia Demo',
    35: 'Australia Bureau',
    74: 'Azure Demo',
    90: 'Azure SmartPayroll'
};

module.exports = AmpersandModel.extend({
    props: {
        username: ['string'],
        password: ['string']
    },
    derived: {
        environment: {
            deps: ['username'],
            fn: function () {
                if (this.username)
                {
                    // Look for environment delimiter
                    var index = this.username.indexOf('\\');
                    if (index >= 0)
                    {
                        var env = this.username.slice(0, index);
                        
                        // Return valid id
                        return envMap[env.toLowerCase()];                            
                    }
                }

                return undefined;
            }
        },
        loginText: {
            deps: ['environment'],
            fn: function () {
                if (this.environment) {
                    // If there is a match change login button text                        
                    return 'Log In to ' + envTextMap[this.environment];                                            
                }

                return 'Log In';
            }
        }
    }
});